import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const FadeInComponent = props => {
  const ref = useRef();

  /*
  useEffect(() => {
    if (props.isFilterable) {
      //add delayed animation and remove it after 600ms (duration time of animation is 500)
      setTimeout(() => {
        const node = ReactDOM.findDOMNode(ref.current);
        if (!!node) {
          node.classList.add("fadeInUp");

          setTimeout(() => {
            const node = ReactDOM.findDOMNode(ref.current);
            if (!!node) {
              node.classList.remove("fadeInUp");
            }
          }, 600);
        }
      });
    }
  });
*/
  return (
    <div
      ref={ref}
      //className={"animated faster" + (!props.isFilterable ? " fadeInUp" : "")}
    >
      {props.children}
    </div>
  );
};

export default FadeInComponent;
