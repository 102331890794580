import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { setDocumentFilter } from "../store/ui/actions";

import { Field, reduxForm } from "redux-form";
import { RFWAutoComplete, RFWSwitch } from "../components/ReduxFormWrapper";
import Loading from "../components/UI/Loading";
import FileUploadFormControl from "../components/UI/FileUploadFormControl";

import Alert from "../components/UI/Alert";
import { isFullversion } from "../util/Auth";
import { createDocument } from "../util/Document";
import { createContractDocument } from "../util/Contract";
import { logError } from "../util/log";

import { getContract } from "../store/contracts/actions";

const useStyles = makeStyles((theme) => ({
  root: { margin: theme.spacing(2) },
  h2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  h4: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validate = (values) => {
  const errors = {};
  const requiredFields = ["documents"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Bitte laden Sie ein Dokument hoch!";
    }
  });
  return errors;
};

const initialValues = {
  doInformPool: true,
};

const CreateDocumentForm = (props) => {
  const classes = useStyles();

  const history = useHistory();

  const {
    type,
    contract,
    isPending,
    contractId,
    getContract,
    handleSubmit,
    submitting,
    customer,
    isCustomerPending,
    label,
  } = props;

  const [showError, setShowError] = useState(false);
  const [errorText] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    if (type === "CONTRACT" && !contract && !!customer)
      getContract(customer.id, contractId);
  }, [isCustomerPending, customer, getContract, type, contract]);

  const onCustomerDocumentSubmit = async (data) => {
    const d = {
      description: data.description,
      doInformPool: data.doInformPool,
      document: data.documents[0],
    };

    const created = await createDocument(customer.id, d);
    return created;
  };

  const onContractDocumentSubmit = async (contractId, data) => {
    const d = {
      description: data.description,
      doInformPool: data.doInformPool,
      document: data.documents[0],
    };
    const created = await createContractDocument(customer.id, contractId, d);
    return created;
  };

  const submit = (data) => {
    setDisableSubmit(true);
    let ret = null;
    if (type === "CUSTOMER") {
      ret = onCustomerDocumentSubmit(data);
    } else {
      ret = onContractDocumentSubmit(contractId, data);
    }

    ret
      .then((res) => {
        if (res == null) {
          setShowError(true);
        } else {
          var retUrl = null;
          if (type === "CUSTOMER") {
            props.setDocumentFilter(res.id);
            retUrl = `/customers/${customer.id}/documents`;
          } else {
            retUrl = `/customers/${customer.id}/contracts/${contractId}`;
          }
          history.push(retUrl);
        }
      })
      .catch((err) => {
        logError(err);
        setShowError(true);
      })
      .finally(() => setDisableSubmit(false));
  };

  if (isCustomerPending) return <Loading />;
  if (type === "CONTRACT" && (isPending || !contract)) return <Loading />;
  if (!customer) return null;

  const doSentToPool =
    isFullversion && (type === "CUSTOMER" || contract.controlledBy === "P") ? (
      <div>
        <Field
          name="doInformPool"
          className={classes.mt1}
          component={RFWSwitch}
          label="Zur Bearbeitung an KAB senden?"
        />
      </div>
    ) : null;

  return (
    <Grid container>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(submit)} className={classes.root}>
          <h2 className={classes.h2}>{label}</h2>

          <div>
            <Field
              name="description"
              className={classes.mt1}
              component={RFWAutoComplete}
              label="Name / Beschreibung"
              url="/metadata/documentTypes"
              placeholder="Bitte geben Sie eine Beschreibung ein"
              freeSolo
            />
          </div>
          {doSentToPool}
          <div>
            <Field
              name="documents"
              className={classes.mt1}
              component={FileUploadFormControl}
              label="Dokument auswählen"
              isMultiple={false}
            />
          </div>
          <Alert
            variant="error"
            isOpen={showError}
            onClose={() => setShowError(false)}
          >
            {errorText ||
              "Dokument konnte nicht erstellt werden, bitte versuchen Sie es später erneut"}
          </Alert>
          <div>
            <Button
              type="submit"
              disabled={submitting || disableSubmit}
              variant="contained"
              color="primary"
              className={classes.mt2}
            >
              Erstellen
            </Button>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  contract: state.contracts.currentContract,
  isPending: state.contracts.isPending,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getContract: getContract,
      setDocumentFilter: setDocumentFilter,
    },
    dispatch
  );

const form = reduxForm({
  form: "CreateDocumentForm", // a unique identifier for this form
  validate,
  initialValues,
})(CreateDocumentForm);

export default connect(mapStateToProps, mapDispatchToProps)(form);
