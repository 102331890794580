import {
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  Grid,
} from "@material-ui/core";
import { Archive, Unarchive } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setPopperState } from "../../store/ui/actions";
import ContractListEntry from "./ContractListEntry";
import FadeInComponent from "../../components/UI/FadeInComponent";
import FABButtonWithPopper from "../../components/UI/FABButtonWithPopper";
import useSearch from "../../hooks/useSearch";
import { ftSearchArray } from "../../util/Utilities";
import ContractState from "../../consts/ContractState";

import { isFullversion } from "../../util/Auth";

var moment = require("moment");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  grow: {
    flex: 1,
  },
  nothingFound: {
    marginLeft: theme.spacing(2),
  },
  new: {
    margin: theme.spacing(1),
  },
}));

const filterContracts = (filterValues, doShowOnlyActive, newContracts) => {
  return (contract) => {
    if (newContracts && newContracts.length > 0) {
      return !newContracts.map((c) => c.id).includes(contract.id);
    }

    let match1 = !filterValues || !filterValues[0];
    let match2 = true;

    if (doShowOnlyActive) {
      match2 =
        ContractState[contract.state] && ContractState[contract.state].isActive;
    }
    if (match1 && match2) return true;

    match1 = filterValues.includes(contract.category.name);
    return match1 && match2;
  };
};

const ContractOverviewList = (props) => {
  const classes = useStyles();
  const [searchQuery] = useSearch();
  const history = useHistory();
  const { contracts, customer, settings, contractFilter } = props;

  let newContracts = [];

  if (contractFilter) {
    newContracts = contracts.filter((f) => contractFilter.id === f.id);
  }

  const displayContracts = ftSearchArray(contracts, searchQuery).filter(
    filterContracts(settings.filter, settings.doShowOnlyActive, newContracts)
  );

  if (settings.sort) {
    displayContracts.sort((a, b) => {
      let a1, b1;

      if (settings.sort === "Gesellschaft") {
        a1 = a.contractor;
        b1 = b.contractor;

        return a1.localeCompare(b1);
      } else if (settings.sort === "Enddatum") {
        a1 = new moment(a.endDate);
        b1 = new moment(b.endDate);

        return a1.valueOf() - b1.valueOf();
      } else if (settings.sort === "Kosten") {
        a1 = a.fee;
        b1 = b.fee;

        return parseInt(a1) - parseInt(b1);
      } else {
        a1 = a.category;
        b1 = b.category;

        return a1.name.localeCompare(b1.name);
      }
      //TODO
    });
  }

  const addNewBtn = (
    <FABButtonWithPopper id="create-contract-popper">
      <List component="nav">
        <ListItem
          button
          onClick={(e) => {
            history.push("/customers/" + customer.id + "/new-contract", {
              isOwnContract: false,
            });
          }}
        >
          <ListItemIcon>
            <Unarchive />
          </ListItemIcon>
          Vertrag an KAB übertragen
        </ListItem>
        {isFullversion() ? (
          <ListItem
            button
            onClick={(e) => {
              history.push("/customers/" + customer.id + "/new-contract", {
                isOwnContract: true,
              });
            }}
          >
            <ListItemIcon>
              <Archive />
            </ListItemIcon>
            eigener Vertrag
          </ListItem>
        ) : null}
      </List>
    </FABButtonWithPopper>
  );

  if (
    !displayContracts ||
    !newContracts ||
    (displayContracts.length === 0 && newContracts.length === 0)
  ) {
    return (
      <>
        <FadeInComponent>
          <span className={classes.nothingFound}>Keine Verträge gefunden</span>
        </FadeInComponent>
        {addNewBtn}
      </>
    );
  }

  return (
    <>
      <FadeInComponent isFilterable={true}>
        <Grid container spacing={1} className={classes.root}>
          {newContracts.map((contract, i) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={contract.id}>
              <ContractListEntry
                contract={contract}
                index={i}
                isFamily={contract.customerId !== customer.id}
                isNew={true}
              />
            </Grid>
          ))}
          {displayContracts.map((contract, i) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={contract.id}>
              <ContractListEntry
                contract={contract}
                index={i}
                isFamily={contract.customerId !== customer.id}
              />
            </Grid>
          ))}
        </Grid>
      </FadeInComponent>
      {addNewBtn}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPopperState: setPopperState,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractOverviewList);
