import React from "react";

const NotNullTag = props => {
  if (!props.value) return null;

  const NNT = `${props.tag}`;

  return <NNT {...props}>{props.children ? props.children : props.value}</NNT>;
};

export default NotNullTag;
