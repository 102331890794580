import { makeStyles } from "@material-ui/core";
import React from "react";
import OverviewListEntry from "../../components/UI/OverviewListEntry";
import { FolderOpen, People } from "@material-ui/icons";
import { Link } from "react-router-dom";

import { getContractFee } from "../../util/Utilities";

const useStyles = makeStyles((theme) => ({
  root: { margin: theme.spacing(2) },
  card: {},

  isFamiliy: {
    backgroundColor: "rgba(0,0,255,0.2)",
  },
  doHighlight: {
    backgroundColor: "rgba(0,255,0,0.2)",
  },
  isInactive: {
    backgroundColor: "rgba(255,0,0,0.2)",
  },
  isFamilyInactive: {
    backgroundImage:
      "repeating-linear-gradient(90deg, rgba(0, 0, 255, 0.2) 0%, rgba(0, 0, 255, 0.2) 2%, rgba(255,0,0,0.2) 2%, rgba(255,0,0,0.2) 4%, rgba(0, 0, 255, 0.2) 4%)",
  },
  label: {
    width: "10em",
    display: "inline-block",
    fontWeight: "bold",
  },
  value: {
    whiteSpace: "nowrap",
  },
  link: {
    textDecoration: "none",
  },
  entry: {
    margin: theme.spacing(2),
  },
}));

const ContractListEntry = (props) => {
  const classes = useStyles();

  const { contract, isNew, isFamily } = { ...props };

  const regIcon = <FolderOpen />;

  return (
    <Link
      to={`/customers/${contract.customerId}/contracts/${contract.id}`}
      className={classes.link}
    >
      <OverviewListEntry
        classNameHeader={classes.header}
        chip={isFamily ? { icon: <People />, label: "Familie" } : null}
        icon={regIcon}
        title={contract.category.name}
        subheader={contract.contractor}
        displayAction={false}
        isNew={isNew}
        className={classes.entry}
      >
        {contract.contractNumber ? (
          <div>
            <span className={classes.label}>Vertragsnummer:</span>
            <span className={classes.value}>{contract.contractNumber}</span>
          </div>
        ) : null}
        {contract.state ? (
          <div>
            <span className={classes.label}>Status:</span>
            <span className={classes.value}>{contract.state}</span>
          </div>
        ) : null}
        {contract.risk ? (
          <div>
            <span className={classes.label}>Risiko:</span>
            <span className={classes.value}>
              {(() => {
                let text = contract.risk.substring(0, 30);
                if (contract.risk.length > 30) text = text + "...";

                return text;
              })()}
            </span>
          </div>
        ) : null}
        {contract.startDate ? (
          <div>
            <span className={classes.label}>Laufzeit:</span>
            <span className={classes.value}>
              {contract.startDate}
              {contract.endDate ? <span> - {contract.endDate}</span> : ""}
            </span>
          </div>
        ) : null}

        {contract.fee ? (
          <div>
            <span className={classes.label}>Beitrag (lt. ZW):</span>
            <span className={classes.value}>{getContractFee(contract)}</span>
          </div>
        ) : null}

        {contract.feePaymentType ? (
          <div>
            <span className={classes.label}>Zahlungsweise:</span>
            <span className={classes.value}>{contract.feePaymentType}</span>
          </div>
        ) : null}
      </OverviewListEntry>
    </Link>
  );
};

export default ContractListEntry;
