import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { useState } from "react";
import HeaderAnonymous from "../../components/layout/HeaderAnonymous";
import Loading from "../../components/UI/Loading";
import Alert from "../../components/UI/Alert";
import { loginPartner, loginEmployee, storeToken } from "../../util/Auth";
import { logError } from "../../util/log";
import useLocalStorage from "../../hooks/useLocalStorage";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(3, 0, 1)
  }
}));

export default function Login(props) {
  const classes = useStyles();

  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPartnerLoggedIn, setIsPartnerLoggedIn] = useState(false);

  const [partnerId, setPartnerId] = useState("");
  const [employeeId, setEmployeeId] = useLocalStorage("employeeId", "");
  const [partnerPassword, setPartnerPassword] = useState("");
  const [employeePassword, setEmployeePassword] = useState("");

  const handleSubmit = async event => {
    event.preventDefault();

    setIsLoading(true);
    setShowError(false);
    setErrorText("");

    try {
      if (isPartnerLoggedIn) _loginEmployee();
      else _loginPartner();
    } catch (ex) {
      logError(ex);
      setShowError(true);
      setIsLoading(false);
    }
  };

  const cancel = () => {
    setIsPartnerLoggedIn(false);
  };
  const gotoCustomers = () => {
    if (props.location.state) {
      props.history.push(props.location.state.from.pathname);
    } else {
      props.history.push("/");
    }
  };

  const _loginPartner = async data => {
    let res = await loginPartner(partnerId, partnerPassword);

    if (res.token) {
      storeToken(res.token);
      gotoCustomers();
    } else {
      const partnerEmployees = res.employees;
      if (!partnerEmployees || partnerEmployees.length === 0) {
        setErrorText("Makler-Id oder Passwort nicht korrekt!");
        setShowError(true);
        setIsLoading(false);
      } else {
        //display employee login
        setIsPartnerLoggedIn(true);
        setEmployees(partnerEmployees);
        setIsLoading(false);
      }
    }
  };

  const _loginEmployee = async data => {
    let res = await loginEmployee(
      partnerId,
      partnerPassword,
      employeeId,
      employeePassword
    );

    if (res.token) {
      storeToken(res.token);
      gotoCustomers();
    } else {
      setErrorText("Mitarbeiter Passwort nicht korrekt!");
      setShowError(true);
      setIsLoading(false);
    }
  };

  const employeeLogin = (
    <>
      <FormControl className={classes.formControl} fullWidth required>
        <InputLabel id="employeeId-label">Mitarbeiterkürzel</InputLabel>

        <Select
          id="employeeId"
          fullWidth
          labelId="employeeId-label"
          value={employeeId}
          onChange={e => setEmployeeId(e.target.value)}
        >
          {employees.map(employee => (
            <MenuItem key={employee.id} value={employee.shortName}>
              {employee.lastName}, {employee.firstName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        margin="normal"
        required
        fullWidth
        name="employeePassword"
        label="Mitarbeiter Passwort"
        type="password"
        id="employeePassword"
        autoComplete="current-password"
        onChange={e => setEmployeePassword(e.target.value)}
        value={employeePassword}
      />
    </>
  );
  const partnerLogin = (
    <>
      <TextField
        margin="normal"
        disabled={isPartnerLoggedIn}
        required
        fullWidth
        id="partnerId"
        label="Makler-Id"
        name="partnerId"
        autoComplete="username"
        onChange={e => setPartnerId(e.target.value)}
        value={partnerId}
        autoFocus
      />
      <TextField
        margin="normal"
        required
        disabled={isPartnerLoggedIn}
        fullWidth
        name="partnerPassword"
        label="Makler Passwort"
        type="password"
        id="partnerPassword"
        autoComplete="current-password"
        onChange={e => setPartnerPassword(e.target.value)}
        value={partnerPassword}
      />
    </>
  );

  const form = (
    <form className={classes.form} onSubmit={handleSubmit} autoComplete="off">
      {partnerLogin}
      {isPartnerLoggedIn ? employeeLogin : null}

      <Alert
        variant="error"
        isOpen={showError}
        onClose={() => setShowError(false)}
      >
        {errorText || "Makler-Id oder Passwort nicht korrekt!"}
      </Alert>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="secondary"
        className={classes.button}
      >
        Anmelden
      </Button>
      {isPartnerLoggedIn ? (
        <Button onClick={cancel} fullWidth variant="contained">
          Abbrechen
        </Button>
      ) : null}
    </form>
  );

  return (
    <>
      <HeaderAnonymous />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Anmelden als Makler
          </Typography>
          {isLoading ? <Loading /> : form}
        </div>
      </Container>
    </>
  );
}
