import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  logo: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(1)
  },

  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  }
}));

const HeaderAnonymous = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <img
            src="/kb_maklersystems_logo_i.png"
            alt=""
            className={classes.logo}
          />

          <Typography className={classes.title} variant="h6" noWrap>
            {process.env.REACT_APP_TITLE}
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default HeaderAnonymous;
