import axios from "../../conf/axios.default";
import moment from "moment";

import * as actionTypes from "./actionTypes";

export function getContractDocuments(customerId, contractId) {
  return (dispatch, getState) => {
    dispatch(getContractDocumentsAPIIsPending());

    let url =
      "/customers/" + customerId + "/contracts/" + contractId + "/documents";

    axios
      .get(url)
      .then(res => {
        //TODO
        if (res.error) {
          throw res.error;
        }
        dispatch(getContractDocumentsIsSuccess(res.data));
      })
      .catch(error => {
        dispatch(getContractDocumentsAPIIsError(error));
      });
  };
}

/*
export function getCustomerDocument(customerId, documentId) {
  return (dispatch, getState) => {
    //no cache, invalid cache or customer id not in cache
    dispatch(getCustomerDocumentsAPIIsPending());
    let url = "/customers/" + customerId + "/documents/" + documentId;

    axios
      .get(url)
      .then(res => {
        //TODO
        if (res.error) {
          throw res.error;
        }
        dispatch(getCustomerDocumentIsSuccess(res.data));
      })
      .catch(error => {
        dispatch(getCustomerDocumentsAPIIsError(error));
      });
  };
}
*/
export function getContractDocumentsIsSuccess(
  documents,
  refreshValidity = true
) {
  return {
    type: actionTypes.GET_CONTRACT_DOCUMENTS_IS_SUCCESS,
    documents: documents,
    refreshValidity: refreshValidity
  };
}

export function getContractDocumentIsSuccess(document) {
  return {
    type: actionTypes.GET_CONTRACT_DOCUMENT_IS_SUCCESS,
    document: document
  };
}

export function getContractDocumentsAPIIsPending() {
  return {
    type: actionTypes.GET_CONTRACT_DOCUMENTS_API_IS_PENDING
  };
}

export function getContractDocumentsAPIIsError(error) {
  return {
    type: actionTypes.GET_CONTRACT_DOCUMENTS_API_IS_ERROR,
    error: error
  };
}
