import { makeStyles, SnackbarContent } from "@material-ui/core";
import { amber, green } from "@material-ui/core/colors";
import {
  CheckCircle,
  Error,
  Info,
  Warning,
  AvTimerOutlined
} from "@material-ui/icons";
import React, { useState, useEffect } from "react";

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info
};

const useStyles = makeStyles(theme => ({
  snackbar: { width: "100%" },

  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));

const Alert = props => {
  const classes = useStyles();
  const { isOpen, title, onClose, variant, isSticky, ...other } = props;
  const Icon = variantIcon[variant];

  let timeout = null;

  const handleClose = event => {
    onClose && onClose();
  };

  useEffect(() => {
    if (isOpen) {
      timeout = setTimeout(() => {
        handleClose();
      }, 6000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isOpen]);

  if (!isOpen && !isSticky) return null;

  return (
    <SnackbarContent
      className={classes[variant] + " " + classes.snackbar}
      message={
        <span className={classes.message}>
          <Icon className={classes.icon + " " + classes.iconVariant} />
          <span>
            {title ? <strong>{title}</strong> : null}
            {other.children}
          </span>
        </span>
      }
      {...other}
    />
  );
};

export default Alert;
