import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core";
import { debounce } from "lodash";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "calc(100vh - 130px)",

    position: "fixed",
    right: 10,
    top: 70,
    width: 75
  },
  tabs: {
    width: "100%"
  },
  height: {
    height: "calc(var(--100vh, 100vh) - 130px)"
  },
  entry: {
    cursor: "pointer",
    padding: 2
  },
  active: {
    fontWeight: "bold",
    fontSize: "larger"
  },
  tab: { minWidth: "auto", minHeight: "auto" },
  spacer: { width: 100 }
}));

const AlphabetNavigation = ({ activeChar, switchActiveChar, tag }) => {
  const classes = useStyles();
  const chars = "A B C D E F G H I J K L M N O P Q R S T U V W X Y Z".split(
    " "
  );
  const [value, setValue] = React.useState(chars.indexOf(activeChar));
  const handleChange = (e, index) => {
    switchActiveChar(chars[index]);
    setValue(index);
  };

  const debouncedResize = debounce(() => {
    let vh = window.innerHeight;
    document.documentElement.style.setProperty("--100vh", `${vh}px`);
  }, 200);

  useEffect(() => {
    window.addEventListener("resize", debouncedResize);
    debouncedResize();

    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);

  const nav = chars.map(c => (
    <Tab label={c} id={c} key={c} className={classes.tab} />
  ));

  return (
    <>
      <div className={classes.root + " " + classes.height}>
        <Tabs
          className={classes.tabs}
          orientation="vertical"
          variant="scrollable"
          scrollButtons="on"
          value={value}
          onChange={handleChange}
        >
          {nav}
        </Tabs>
      </div>
    </>
  );
};

export default AlphabetNavigation;
