import moment from "moment";

import * as actionTypes from "./actionTypes";
import * as Utilities from "../../util/Utilities";

const initialState = {
  isPending: false,
  contracts: [],
  currentContract: null,
  error: null,
  validUntil: null
};

const getUpdatedContractsIsSuccess = (state, action) => {
  const updated = Utilities.updateStateObject(state, {
    isPending: false,
    contracts: action.contracts
  });

  if (action.refreshValidity) {
    const d = process.env.REACT_APP_API_CACHE_DURATION;
    updated.validUntil = moment(new Date()).add(JSON.parse(d));
  }
  return updated;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTRACTS_API_IS_PENDING:
      return Utilities.updateStateObject(state, {
        isPending: true,
        error: null
      });

    case actionTypes.GET_CONTRACTS_IS_SUCCESS:
      return getUpdatedContractsIsSuccess(state, action);

    case actionTypes.GET_CONTRACT_IS_SUCCESS:
      return Utilities.updateStateObject(state, {
        isPending: false,
        currentContract: action.contract
      });

    case actionTypes.GET_CONTRACTS_API_IS_ERROR:
      return Utilities.updateStateObject(state, {
        isPending: false,
        error: action.error
      });

    case actionTypes.CREATE_CONTRACT_API_IS_ERROR:
      return Utilities.updateStateObject(state, {
        isPending: false,
        error: action.error
      });

    case actionTypes.CREATE_CONTRACT_API_IS_PENDING:
      return Utilities.updateStateObject(state, {
        isPending: true,
        error: null
      });

    case actionTypes.CREATE_CONTRACT_IS_SUCCESS:
      return Utilities.updateStateObject(state, {
        isPending: false,
        currentContract: action.contract
      });
    default:
      return state;
  }
};
