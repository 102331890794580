export const GET_CONTRACT_DOCUMENTS_API_IS_PENDING =
  "GET_CONTRACT_DOCUMENTS_API_IS_PENDING";
export const GET_CONTRACT_DOCUMENTS_API_IS_ERROR =
  "GET_CONTRACT_DOCUMENTS_API_IS_ERROR";

export const GET_CONTRACT_DOCUMENTS = "GET_CONTRACT_DOCUMENTS";
export const GET_CONTRACT_DOCUMENT = "GET_CONTRACT_DOCUMENT";
export const GET_CONTRACT_DOCUMENTS_IS_SUCCESS =
  "GET_CONTRACT_DOCUMENTS_IS_SUCCESS";
export const GET_CONTRACT_DOCUMENT_IS_SUCCESS =
  "GET_CONTRACT_DOCUMENT_IS_SUCCESS";
