import * as actionTypes from "./actionTypes";
import * as Utilities from "../../util/Utilities";

const initialState = {
  contractsOverviewSettings: {
    filter: [],
    sort: "Typ",
    doIncludeFamily: false,
    doShowOnlyActive: true
  },
  contractsOverviewContractFilter: null,
  documentOverviewDocumentFilter: null,
  searchSettings: {
    isSearchDisplayed: true,
    searchValue: ""
  },
  popperState: {},
  currentCustomer: null,
  customerOverviewCurrentSettings: {
    activeChar: "A",
    searchValue: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTRACTS_OVERVIEW_SETTINGS:
      return Utilities.updateStateObject(state, {
        contractsOverviewSettings: {
          filter: action.filter.slice(0),
          sort: action.sort,
          doIncludeFamily: action.doIncludeFamily,
          doShowOnlyActive: action.doShowOnlyActive,
          filteredContract: action.activeContract
        }
      });
    case actionTypes.SET_CURRENT_CUSTOMER:
      return Utilities.updateStateObject(state, {
        currentCustomer: action.currentCustomer
      });

    case actionTypes.SET_CUSTOMER_OVERVIEW_CURRENT_SETTINGS:
      return Utilities.updateStateObject(state, {
        customerOverviewCurrentSettings: action.settings
      });

    case actionTypes.SET_CONTRACTS_OVERVIEW_CONTRACT_FILTER:
      return Utilities.updateStateObject(state, {
        contractsOverviewContractFilter: action.contract
      });

    case actionTypes.SET_DOCUMENTS_OVERVIEW_DOCUMENT_FILTER:
      return Utilities.updateStateObject(state, {
        documentOverviewDocumentFilter: action.document
      });
    case actionTypes.SET_POPPER_STATE:
      const newState = Utilities.updateStateObject(state, {
        popperState: { ...state.popperState }
      });
      newState.popperState[action.id] = action.state;
      return newState;

    case actionTypes.SET_IS_SEARCH_DISPLAYED:
      return Utilities.updateStateObject(state, {
        searchSettings: Utilities.updateStateObject(state.searchSettings, {
          isSearchDisplayed: action.isSearchDisplayed
        })
      });

    case actionTypes.SET_SEARCH_VALUE:
      return Utilities.updateStateObject(state, {
        searchSettings: Utilities.updateStateObject(state.searchSettings, {
          searchValue: action.searchValue
        })
      });

    default:
      return state;
  }
};
