import React from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  makeStyles
} from "@material-ui/core";

import { Done, CancelOutlined } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  list: {
    maxHeight: "50vh",
    overflow: "auto",
    minWidth: "200px"
  },
  button: {
    margin: theme.spacing(1),
    xxwidth: "95%"
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ListSorter = props => {
  const classes = useStyles();

  const handleToggle = value => () => {
    props.onOk(value);
  };

  return (
    <>
      <List component="nav" className={classes.list}>
        {props.items.map((item, i) => (
          <ListItem
            dense
            button
            onClick={handleToggle(item)}
            key={i}
            selected={props.selected === item}
          >
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default ListSorter;
