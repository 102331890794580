import React from "react";
import ReactDOM from "react-dom";

import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./components/layout/Theme";

import { applyMiddleware, createStore, combineReducers, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import customersReducer from "./store/customers/reducer";
import contractsReducer from "./store/contracts/reducer";
import uiReducer from "./store/ui/reducer";
import customerdocumentsReducer from "./store/customer-documents/reducer";
import contractdocumentsReducer from "./store/contract-documents/reducer";
import employeesReducer from "./store/employees/reducer";
import { reducer as reduxFormReducer } from "redux-form";

import "./index.css";
import "animate.css/animate.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const middlewares = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
  customers: customersReducer,
  contracts: contractsReducer,
  employees: employeesReducer,
  customerdocuments: customerdocumentsReducer,
  contractdocuments: contractdocumentsReducer,
  ui: uiReducer,
  form: reduxFormReducer
});
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
