import React, { useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Hidden } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import Loading from "../../components/UI/Loading";
import CustomerDocumentsOverviewList from "./CustomerDocumentsOverviewList";
import { getCustomerDocuments } from "../../store/customer-documents/actions";

import { setDocumentFilter } from "../../store/ui/actions";

const CustomerDocumentsOverview = props => {
  const {
    documents,
    getCustomerDocuments,
    error,
    isCustomerPending,
    isDocumentsPending,
    customer,
    setDocumentFilter
  } = props;
  const history = useHistory();

  if (error) {
    history.push("/error", { error: error });
  }

  useEffect(() => {
    if (!customer) return;
    getCustomerDocuments(customer.id);

    return () => {
      setDocumentFilter(null);
    };
  }, [getCustomerDocuments, customer, setDocumentFilter]);

  if (isCustomerPending || isDocumentsPending || !customer) return <Loading />;

  return (
    <>
      <Hidden smUp>
        <CustomerDocumentsOverviewList
          customerId={customer.id}
          documents={documents}
          documentFilter={props.documentFilter}
        ></CustomerDocumentsOverviewList>
      </Hidden>
      <Hidden xsDown>
        <CustomerDocumentsOverviewList
          customerId={customer.id}
          documents={documents}
          documentFilter={props.documentFilter}
        ></CustomerDocumentsOverviewList>
      </Hidden>
    </>
  );
};

const mapStateToProps = state => ({
  error: state.customerdocuments.error,
  documents: state.customerdocuments.documents,
  isDocumentsPending: state.customerdocuments.isPending,
  documentFilter: state.ui.documentOverviewDocumentFilter
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCustomerDocuments: customerId => getCustomerDocuments(customerId),
      setDocumentFilter: setDocumentFilter
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDocumentsOverview);
