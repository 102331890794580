import * as actionTypes from "./actionTypes";

export function resetContractsOverviewSettings() {
  return {
    type: actionTypes.SET_CONTRACTS_OVERVIEW_SETTINGS,
    sort: "Typ",
    filter: [],
    doIncludeFamily: false,
    doShowOnlyActive: true
  };
}

export function setContractsOverviewSettings(
  sort,
  filter,
  doIncludeFamily,
  doShowOnlyActive
) {
  return {
    type: actionTypes.SET_CONTRACTS_OVERVIEW_SETTINGS,
    sort: sort,
    filter: filter,
    doIncludeFamily: doIncludeFamily,
    doShowOnlyActive: doShowOnlyActive
  };
}

export function setContractFilter(contract) {
  return {
    type: actionTypes.SET_CONTRACTS_OVERVIEW_CONTRACT_FILTER,
    contract: contract
  };
}

export function setDocumentFilter(document) {
  return {
    type: actionTypes.SET_DOCUMENTS_OVERVIEW_DOCUMENT_FILTER,
    document: document
  };
}

export function setCurrentCustomer(customer) {
  return {
    type: actionTypes.SET_CURRENT_CUSTOMER,
    currentCustomer: customer
  };
}

export function setCustomerOverviewCurrentSettings(settings) {
  return {
    type: actionTypes.SET_CUSTOMER_OVERVIEW_CURRENT_SETTINGS,
    settings: settings
  };
}

export function setPopperState(id, state) {
  return {
    type: actionTypes.SET_POPPER_STATE,
    id: id,
    state: state
  };
}

export function setIsSearchDisplayed(isSearchDisplayed) {
  return {
    type: actionTypes.SET_IS_SEARCH_DISPLAYED,
    isSearchDisplayed: isSearchDisplayed
  };
}

export function setSearchValue(searchValue) {
  return {
    type: actionTypes.SET_SEARCH_VALUE,
    searchValue: searchValue
  };
}
