import React, { useEffect } from "react";
import { getMe } from "../../store/employees/actions";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loading from "../../components/UI/Loading";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  h2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  c1: {
    fontWeight: "bold",
    paddingRight: theme.spacing(2)
  }
}));

const MyProfile = props => {
  const classes = useStyles();
  const history = useHistory();
  const { isPending, employee, error, getMe } = props;

  if (error) {
    history.push("/error", { error: error });
  }

  useEffect(() => {
    getMe();
  }, [getMe]);

  if (isPending) return <Loading />;
  if (!employee) {
    return <div className={classes.root}>Keine Profil gefunden</div>;
  }

  return (
    <div className={classes.root}>
      <h2 className={classes.h2}>
        {employee.firstName} {employee.lastName}
      </h2>
      <hr />
      <table>
        <tr>
          <td className={classes.c1}>Straße</td>
          <td>{employee.street}</td>
        </tr>
        <tr>
          <td className={classes.c1}>PLZ / Ort</td>
          <td>
            {employee.zip} / {employee.city}
          </td>
        </tr>
        <tr>
          <td className={classes.c1}>Telefon</td>
          <td>{employee.phone}</td>
        </tr>
        <tr>
          <td className={classes.c1}>Fax</td>
          <td>{employee.fax}</td>
        </tr>
        <tr>
          <td className={classes.c1}>Mobile</td>
          <td>{employee.phoneMobile}</td>
        </tr>
        <tr>
          <td className={classes.c1}>E-Mail</td>
          <td>{employee.mail}</td>
        </tr>
      </table>
    </div>
  );
};

const mapStateToProps = state => ({
  error: state.employees.error,
  employee: state.employees.me,
  isPending: state.employees.isPending
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getMe: getMe
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
