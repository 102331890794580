import React, { Component } from "react";
import { logError } from "../../util/log";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(2),
  },
});

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // log the error to loggly
    console.log("Attehntion - todo");
    console.log(error);
    console.log(info);

    logError(error);
    //logger.push({ error, info });
  }

  render() {
    const { classes } = this.props;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={classes.root}>
          <h1>Bitte entschuldigen Sie,</h1>
          <span>hier ist etwas schief gegangen.</span>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);
