import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "./layout/Layout";
import { isAuthenticated } from "../util/Auth";
const PrivateRoute = ({ component: Component, ...other }) => {
  return (
    <Route
      {...other}
      render={props =>
        isAuthenticated() ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
