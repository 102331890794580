import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  InputBase,
  IconButton,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";

import { Search, Cancel, AccountCircle } from "@material-ui/icons";

import { fade, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import { debounce } from "lodash";
import useSearch from "../../hooks/useSearch";
import IconButtonWithPopper from "../UI/IconButtonWithPopper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setPopperState } from "../../store/ui/actions";
import { logout } from "../../util/Auth";

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  logo: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },

  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 400
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: theme.palette.common.white
  },
  iconButton: {
    color: "inherit",
    padding: theme.spacing(1)
  }
}));

const debouncedSearch = debounce((v, f) => {
  if (!v || v.length >= 3) f(v);
}, 300);

const Header = props => {
  const classes = useStyles();
  const [currentSearchValue, setCurrentSearchValue] = useState("");
  const [searchValue, isSearchDisplayed, updateStoreSearchValue] = useSearch();
  const history = useHistory();

  const menuId = "Header-UserBtnMenu";

  useEffect(() => {
    setCurrentSearchValue(searchValue);
  }, [searchValue]);

  const updateSearchValue = e => {
    const v = e.target.value;

    setCurrentSearchValue(v);
    debouncedSearch(v, updateStoreSearchValue);
  };

  const clearSearchValue = () => {
    setCurrentSearchValue("");
    updateStoreSearchValue("");
  };

  const gotoProfile = () => {
    props.setPopperState(menuId, false);
    history.push("/my-profile");
  };
  const gotoKAB = () => {
    props.setPopperState(menuId, false);
    window.open(
      "https://www.kab-maklerservice.de/application/files/8115/8878/1880/KAB_Makleroffice_Nutzungsvereinbarung.pdf",
      "_blank"
    );
  };

  const logoutUser = () => {
    props.setPopperState(menuId, false);
    logout();
    history.push("/login");
  };

  return (
    <div className={classes.grow}>
      <AppBar position="fixed">
        <Toolbar>
          <img
            src="/kb_maklersystems_logo_i.png"
            alt=""
            className={classes.logo}
          />

          <Typography className={classes.title} variant="h6" noWrap>
            {process.env.REACT_APP_TITLE}
          </Typography>
          {!!isSearchDisplayed ? (
            <div className={classes.search}>
              <IconButton className={classes.iconButton}>
                <Search />
              </IconButton>
              <InputBase
                className={classes.input}
                placeholder="Suche"
                inputProps={{}}
                value={currentSearchValue}
                onChange={updateSearchValue}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    event.target.blur();
                  }
                }}
              />
              {!!currentSearchValue ? (
                <IconButton
                  className={classes.iconButton}
                  onClick={clearSearchValue}
                >
                  <Cancel />
                </IconButton>
              ) : null}
            </div>
          ) : (
            <div className={classes.grow}></div>
          )}

          <div>
            <IconButtonWithPopper id={menuId} icon={<AccountCircle />}>
              <List component="nav" className={classes.list}>
                <ListItem dense button onClick={() => gotoProfile()}>
                  <ListItemText primary="Mein Profil" />
                </ListItem>
                <ListItem dense button onClick={() => gotoKAB()}>
                  <ListItemText primary="KAB Makleroffice bestellen" />
                </ListItem>
                <ListItem dense button onClick={() => logoutUser()}>
                  <ListItemText primary="Abmelden" />
                </ListItem>
              </List>
            </IconButtonWithPopper>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPopperState: setPopperState
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
