import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  makeStyles
} from "@material-ui/core";
import {
  Apartment,
  Group,
  House,
  MapOutlined,
  PermContactCalendar,
  Archive,
  Unarchive,
  Attachment
} from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect } from "react";

import Loading from "../../components/UI/Loading";
import NotNullTag from "../../components/UI/NotNullTag";
import PhoneLink from "../../components/UI/PhoneLink";
import FadeInComponent from "../../components/UI/FadeInComponent";
import FABButtonWithPopper from "../../components/UI/FABButtonWithPopper";

import useSearch from "../../hooks/useSearch";

import { asDisplayDate } from "../../util/Utilities";
import { isFullversion } from "../../util/Auth";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  sublist: {
    listStyleType: "none",
    width: "100%",
    padding: 0
  },
  addressContainer: { display: "flex" },
  address: { flex: 1, display: "inline-flex", alignItems: "center" },
  fabWrapper: {
    position: "fixed",
    bottom: "64px",
    right: "0",
    top: "auto",
    textAlign: "right",
    width: "100%"
  },
  fab: {
    margin: theme.spacing(1)
  }
}));

const CustomerViewPanel = props => {
  const classes = useStyles();
  const history = useHistory();
  const { isCustomerPending, customer } = props;
  const [, isSearchDisplayed, , setIsSearchDisplayed] = useSearch();

  useEffect(() => {
    setIsSearchDisplayed(false);

    return () => {
      setIsSearchDisplayed(true);
    };
  }, [isSearchDisplayed]);

  if (isCustomerPending) return <Loading />;
  if (!customer) return null;

  const openOnMaps = () => {
    const address = customer.street + "," + customer.zip + " " + customer.city;
    window.open(
      "https://www.google.com/maps/search/?api=1&query=" + address,
      "_blank"
    );
  };

  const privateValue = (
    customer.salutation +
    " " +
    customer.title +
    " " +
    customer.firstName +
    " " +
    customer.lastName
  ).trim();

  const businessValue = "Firma " + customer.companyName;
  return (
    <>
      <FadeInComponent isFilterable={false}>
        <List className={classes.root}>
          <ListItem>
            <ListItemIcon>
              <House />
            </ListItemIcon>
            <ul className={classes.sublist}>
              <NotNullTag
                className={classes.entry}
                value={customer.companyName ? businessValue : privateValue}
                tag="li"
              ></NotNullTag>
              <NotNullTag value={customer.street} tag="li"></NotNullTag>
              <NotNullTag value={customer.zip + " " + customer.city} tag="li">
                <div className={classes.addressContainer}>
                  <span className={classes.address}>
                    {customer.zip} {customer.city}
                  </span>
                  <span>
                    <IconButton
                      className={classes.button}
                      color="inherit"
                      size="small"
                      onClick={() => openOnMaps()}
                    >
                      <MapOutlined fontSize="small" />
                    </IconButton>
                  </span>
                </div>
              </NotNullTag>
              <NotNullTag value={customer.poBox} tag="li"></NotNullTag>
            </ul>
          </ListItem>
          {customer.phoneMobile ||
          customer.phonePrivate ||
          customer.phoneBusiness ||
          customer.fax ||
          customer.socialNetwork01 ||
          customer.socialNetwork02 ||
          customer.homepage ||
          customer.mail01 ||
          customer.mail02 ? (
            <>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <PermContactCalendar />
                </ListItemIcon>
                <ul className={classes.sublist}>
                  <NotNullTag value={customer.phoneMobile} tag="li">
                    <PhoneLink
                      label="Mobil:"
                      number={customer.phoneMobile}
                      displayWhatsApp={true}
                    ></PhoneLink>
                  </NotNullTag>
                  <NotNullTag tag="li" value={customer.phonePrivate}>
                    <PhoneLink
                      label="Privat:"
                      number={customer.phonePrivate}
                    ></PhoneLink>
                  </NotNullTag>

                  <NotNullTag value={customer.phoneBusiness} tag="li">
                    <PhoneLink
                      label="Geschäftlich"
                      number={customer.phoneBusiness}
                    ></PhoneLink>
                  </NotNullTag>
                  <NotNullTag value={customer.mail01} tag="li"></NotNullTag>
                  <NotNullTag value={customer.mail02} tag="li"></NotNullTag>
                  <NotNullTag value={customer.fax} tag="li">
                    Fax: {customer.fax}
                  </NotNullTag>
                  <NotNullTag
                    value={customer.socialNetwork01}
                    tag="li"
                  ></NotNullTag>
                  <NotNullTag
                    value={customer.socialNetwork02}
                    tag="li"
                  ></NotNullTag>
                  <NotNullTag value={customer.homepage} tag="li"></NotNullTag>
                </ul>
              </ListItem>
            </>
          ) : null}
          {customer.nationality ||
          customer.birthday ||
          customer.birthName ||
          customer.birthPlace ||
          customer.familyState ||
          (customer.familyMember && customer.familyMember.length > 0) ? (
            <>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <Group />
                </ListItemIcon>

                <ul className={classes.sublist}>
                  <NotNullTag
                    value={customer.nationality}
                    tag="li"
                  ></NotNullTag>
                  <NotNullTag
                    value={asDisplayDate(customer.birthday)}
                    tag="li"
                  ></NotNullTag>
                  <NotNullTag value={customer.birthName} tag="li"></NotNullTag>
                  <NotNullTag value={customer.birthPlace} tag="li"></NotNullTag>
                  <NotNullTag
                    value={customer.familyState}
                    tag="li"
                  ></NotNullTag>
                  {customer.familyMember && customer.familyMember.length > 0
                    ? customer.familyMember.map(member => (
                        <li key={member.id}>
                          <Link to={"/customers/" + member.id}>
                            {member.companyName ||
                              member.lastName + ", " + member.firstName}
                          </Link>
                        </li>
                      ))
                    : null}
                </ul>
              </ListItem>
            </>
          ) : null}
          {customer.company ||
          customer.profession ||
          customer.commerceChamberId ? (
            <>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <Apartment />
                </ListItemIcon>

                <ul className={classes.sublist}>
                  <NotNullTag value={customer.company} tag="li"></NotNullTag>
                  <NotNullTag value={customer.profession} tag="li"></NotNullTag>
                  <NotNullTag
                    value={customer.commerceChamberId}
                    tag="li"
                  ></NotNullTag>
                </ul>
              </ListItem>
            </>
          ) : null}
        </List>
      </FadeInComponent>
      <FABButtonWithPopper id="create-contract-popper">
        <List component="nav">
          <ListItem
            button
            onClick={e => {
              history.push("/customers/" + customer.id + "/new-contract", {
                isOwnContract: false
              });
            }}
          >
            <ListItemIcon>
              <Unarchive />
            </ListItemIcon>
            Vertrag an KAB übertragen
          </ListItem>
          {isFullversion() ? (
            <ListItem
              button
              onClick={e => {
                history.push("/customers/" + customer.id + "/new-contract", {
                  isOwnContract: true
                });
              }}
            >
              <ListItemIcon>
                <Archive />
              </ListItemIcon>
              eigener Vertrag
            </ListItem>
          ) : null}

          <ListItem
            button
            onClick={e => {
              history.push("/customers/" + customer.id + "/new-document", {
                isOwnContract: false
              });
            }}
          >
            <ListItemIcon>
              <Attachment />
            </ListItemIcon>
            Neues Kundendokument
          </ListItem>
        </List>
      </FABButtonWithPopper>
    </>
  );
};

export default CustomerViewPanel;
