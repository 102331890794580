import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory, useLocation } from "react-router-dom";
import { setContractFilter } from "../store/ui/actions";

import { Field, reduxForm } from "redux-form";
import { RFWTextField, RFWAutoComplete } from "../components/ReduxFormWrapper";
import Loading from "../components/UI/Loading";
import FileUploadFormControl from "../components/UI/FileUploadFormControl";
import { createContract } from "../util/Contract";
import Alert from "../components/UI/Alert";

import { logError } from "../util/log";

const useStyles = makeStyles((theme) => ({
  root: { margin: theme.spacing(2) },
  h2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  h4: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },

  mt1: {
    marginTop: theme.spacing(1),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validate = (values) => {
  const errors = {};
  const requiredFields = ["contractor", "category", "contractNumber"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Bitte geben Sie einen Wert ein!";
    }
  });

  return errors;
};

const CreateContractForm = (props) => {
  const classes = useStyles();
  const { handleSubmit, submitting, customer, isCustomerPending } = props;
  const history = useHistory();
  const location = useLocation();
  const [showError, setShowError] = useState(false);
  const [errorText] = useState("");
  const [isOwnContract] = useState(
    !location.state || !!location.state.isOwnContract
  );
  const [disableSubmit, setDisableSubmit] = useState(false);

  const submit = async (data) => {
    setDisableSubmit(true);
    const d = {
      contract: data,
      documents: files.map((f) => {
        //todo display discription and inform pool
        return { document: f };
      }),
      isPoolContract: !isOwnContract,
    };

    try {
      const newContract = await createContract(customer.id, d);
      props.setContractFilter(newContract);
      history.push(`contracts`);
    } catch (ex) {
      setShowError(true);
      logError(ex);
    } finally {
      setDisableSubmit(false);
    }
  };

  const [files, setFiles] = useState([]);
  const onDocumentsChange = (newFiles) => {
    setFiles(newFiles);
  };

  if (isCustomerPending) return <Loading />;
  if (!customer) return null;

  return (
    <Grid container>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(submit)} className={classes.root}>
          <h2 className={classes.h2}>
            Neuer Vertrag für {customer.lastName}, {customer.firstName}
          </h2>

          <h4 className={classes.h4}>
            {isOwnContract
              ? "In den eigenen Bestand aufnehmen"
              : "An KAB übertragen"}
          </h4>
          {!isOwnContract ? (
            <Alert variant="warning" title="Bitte beachten Sie" isSticky={true}>
              , dass wir eine unterschriebene Vollmacht des Kunden benötigen.
              Sie müssen diese als Kundendokument an das Systemübertragen.
            </Alert>
          ) : null}
          <div>
            <Field
              name="contractor"
              className={classes.mt1}
              component={RFWAutoComplete}
              label="Gesellschaft"
              optionLabel="name"
              optionValue="id"
              url="/metadata/companies"
              placeholder="Bitte geben Sie den Namen der Gesellschaft ein..."
            />
          </div>
          <div>
            <Field
              name="category"
              className={classes.mt1}
              component={RFWAutoComplete}
              label="Sparte"
              optionLabel="name"
              optionValue="id"
              url="/metadata/contractCategories"
              placeholder="Bitte geben Sie Sparte ein..."
            />
          </div>
          <div>
            <Field
              name="contractNumber"
              component={RFWTextField}
              label="Vertragsnummer"
            />
          </div>
          <div>
            <FileUploadFormControl
              onChange={onDocumentsChange}
              label="Dokument(e) auswählen"
              isMultiple={true}
              meta={{}}
              input={{}}
            />
          </div>
          <Alert
            variant="error"
            isOpen={showError}
            onClose={() => setShowError(false)}
          >
            {errorText ||
              "Vertrag konnte nicht erstellt werden, bitte versuchen Sie es später erneut"}
          </Alert>
          <div>
            <Button
              type="submit"
              disabled={submitting || disableSubmit}
              variant="contained"
              color="primary"
              className={classes.mt2}
            >
              Erstellen
            </Button>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setContractFilter: setContractFilter,
    },
    dispatch
  );

const form = reduxForm({
  form: "CreateContractForm", // a unique identifier for this form
  validate,
})(CreateContractForm);

export default connect(mapStateToProps, mapDispatchToProps)(form);
