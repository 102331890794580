import { useEffect } from "react";
import useSelectors from "./useSelectors";
import useActionCreators from "./useActionCreators";
import { setSearchValue, setIsSearchDisplayed } from "../store/ui/actions";

export default function useSearch() {
  const [searchValue, isSearchDisplayed] = useSelectors(
    state => state.ui.searchSettings.searchValue,
    state => state.ui.searchSettings.isSearchDisplayed
  );

  const [setStoreIsSearchDisplayed, setStoreSearchValue] = useActionCreators(
    setIsSearchDisplayed,
    setSearchValue
  );

  useEffect(() => {
    return () => {
      setStoreSearchValue("");
    };
  }, []);

  return [
    searchValue,
    isSearchDisplayed,
    setStoreSearchValue,
    setStoreIsSearchDisplayed
  ];
}
