import { AppBar, makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  useLocation,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ContractOverview from "../ContractOverview/ContractOverview";
import CustomerDocumentsOverview from "../CustomerDocumentsOverview/CustomerDocumentsOverview";
import CustomerViewPanel from "./CustomerViewPanel";
import CreateContractForm from "../../forms/CreateContractForm";

import ContractDetails from "../ContractDetails/ContractDetails";
import { getCustomer } from "../../store/customers/actions";
import {
  resetContractsOverviewSettings,
  setCurrentCustomer
} from "../../store/ui/actions";
import CreateDocumentForm from "../../forms/CreateDocumentForm";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  selectedTabIndicator: {
    backgroundColor: theme.palette.primary.main
  },
  backTab: {
    minWidth: "50px"
  },
  tab: {
    maxWidth: "none !important"
  },
  tempTab: {
    cursor: "auto"
  },
  tempTabCloseAction: {
    cursor: "pointer",
    verticalAlign: "middle"
  }
}));

const Customer = props => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const initialState = () => {
    const path = location.pathname;
    if (path.endsWith("new-contract")) {
      return "/contracts";
    } else if (path.endsWith("new-document")) {
      return "/documents";
    } else {
      return "/";
    }
  };
  const [tabsValue, setTabsValue] = useState(initialState);
  const params = props.match.params;
  const customerId = params.id;

  const {
    customer,
    error,
    isPending,
    getCustomer,
    setCurrentCustomer,
    currentCustomer,
    resetContractsOverviewSettings
  } = props;

  useEffect(() => {
    if (error) {
      history.push("/error", { error: error });
    }
  }, [error, history]);

  useEffect(() => {
    getCustomer(customerId, true);
    return () => {
      resetContractsOverviewSettings();
    };
  }, [getCustomer, customerId, resetContractsOverviewSettings]);

  useEffect(() => {
    if (customer && (!currentCustomer || customer.id !== currentCustomer.id)) {
      setCurrentCustomer(customer);
    }

    return () => {
      if (!!currentCustomer) setCurrentCustomer(null);
    };
  }, [customer, currentCustomer, setCurrentCustomer]);

  useEffect(() => {
    const path = location.pathname;
    if (
      path.endsWith("new-contract") ||
      path.endsWith("new-contract-document") ||
      path.indexOf("/contracts/") > 0
    ) {
      setTabsValue("/contracts");
    } else if (path.endsWith("new-document")) {
      setTabsValue("/documents");
    } else {
      let url = path.replace(match.url, "");
      url = url.endsWith("/") ? url.slice(0, -1) : url;
      setTabsValue(url || "/");
    }
  }, [location.pathname, match.url]);

  if (error) return null;

  const handleChange = (event, value) => {
    setTabsValue(value);
    const baseUrl = match.url.endsWith("/")
      ? match.url.slice(0, -1)
      : match.url;

    history.push(baseUrl + value);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="secondary">
        <Tabs
          value={tabsValue}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          classes={{
            indicator: classes.selectedTabIndicator
          }}
        >
          <Tab label="Kontakt" value="/" className={classes.tab} />
          <Tab label="Verträge" value="/contracts" className={classes.tab} />
          <Tab label="Dokumente" value="/documents" className={classes.tab} />
        </Tabs>
      </AppBar>
      <>
        <Switch>
          <Route
            path="/customers/:id/contracts/:contractId/new-contract-document"
            render={renderprops => (
              <CreateDocumentForm
                type="CONTRACT"
                customer={customer}
                contractId={renderprops.match.params.contractId}
                isCustomerPending={isPending}
                label={`Neues Vertragsdokument`}
                {...renderprops}
              ></CreateDocumentForm>
            )}
          />
          <Route
            path="/customers/:id/contracts/:contractId"
            render={renderprops => (
              <ContractDetails
                customer={customer}
                isCustomerPending={isPending}
                {...renderprops}
              />
            )}
          />
          <Route
            path="/customers/:id/contracts"
            render={() => (
              <ContractOverview
                customer={customer}
                isCustomerPending={isPending}
              />
            )}
          />

          <Route
            path="/customers/:id/documents"
            render={() => (
              <CustomerDocumentsOverview
                customer={customer}
                isCustomerPending={isPending}
              ></CustomerDocumentsOverview>
            )}
          />
          <Route
            path="/customers/:id/new-contract"
            render={() => (
              <CreateContractForm
                customer={customer}
                isCustomerPending={isPending}
              ></CreateContractForm>
            )}
          />
          <Route
            path="/customers/:id/new-document"
            render={() => (
              <CreateDocumentForm
                type="CUSTOMER"
                customer={customer}
                isCustomerPending={isPending}
                label={
                  !customer
                    ? "-"
                    : `Neues Kundendokument für ${customer.lastName}, ${customer.firstName}`
                }
              ></CreateDocumentForm>
            )}
          />

          <Route
            path="/customers/:id"
            exact
            render={() => (
              <CustomerViewPanel
                customer={customer}
                isCustomerPending={isPending}
              ></CustomerViewPanel>
            )}
          />
        </Switch>
      </>
    </div>
  );
};

const mapStateToProps = state => ({
  error: state.customers.error,
  customer: state.customers.currentCustomer,
  isPending: state.customers.isPending,
  currentCustomer: state.ui.currentCustomer
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCustomer: getCustomer,
      resetContractsOverviewSettings: resetContractsOverviewSettings,
      setCurrentCustomer: setCurrentCustomer
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
