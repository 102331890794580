import moment from "moment";

import * as actionTypes from "./actionTypes";
import * as Utilities from "../../util/Utilities";

const initialState = {
  isPending: false,
  documents: [],
  error: null,
  validUntil: null
};

const getUpdatedContractDocumentsIsSuccess = (state, action) => {
  const updated = Utilities.updateStateObject(state, {
    isPending: false,
    documents: action.documents
  });

  if (action.refreshValidity) {
    const d = process.env.REACT_APP_API_CACHE_DURATION;
    updated.validUntil = moment(new Date()).add(JSON.parse(d));
  }
  return updated;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTRACT_DOCUMENTS_API_IS_PENDING:
      return Utilities.updateStateObject(state, {
        isPending: true,
        error: null
      });

    case actionTypes.GET_CONTRACT_DOCUMENTS_IS_SUCCESS:
      return getUpdatedContractDocumentsIsSuccess(state, action);

    case actionTypes.GET_CONTRACT_DOCUMENT_IS_SUCCESS:
      return Utilities.updateStateObject(state, {
        isPending: false,
        document: action.document
      });

    case actionTypes.GET_CONTRACT_DOCUMENTS_API_IS_ERROR:
      return Utilities.updateStateObject(state, {
        isPending: false,
        error: action.error
      });
    default:
      return state;
  }
};
