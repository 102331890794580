import { makeStyles, Grid, Link } from "@material-ui/core";

import { Attachment, PhotoCamera } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import OverviewListEntry from "../../components/UI/OverviewListEntry";
import FadeInComponent from "../../components/UI/FadeInComponent";
import { List, ListItem, ListItemIcon } from "@material-ui/core";
import FABButtonWithPopper from "../../components/UI/FABButtonWithPopper";
import useSearch from "../../hooks/useSearch";
import {
  ftSearchArray,
  asDisplayDate,
  getOpenDocumentHref
} from "../../util/Utilities";

var moment = require("moment");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  grow: {
    flex: 1
  },
  label: {
    width: "90px",
    display: "inline-block",
    fontWeight: "bold"
  },
  value: {
    whiteSpace: "nowrap"
  },
  link: {
    "&:hover": {
      textDecoration: "none"
    }
  },
  nothingFound: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  entry: {
    margin: theme.spacing(2)
  }
}));

const CustomerDocumentsOverviewList = props => {
  const classes = useStyles();
  const [searchQuery] = useSearch();
  const history = useHistory();
  const { documents, customerId, documentFilter } = props;

  const displayDocuments = ftSearchArray(documents, searchQuery);

  displayDocuments &&
    displayDocuments.sort((a, b) => {
      if (documentFilter) {
        if (a.id === documentFilter.id) {
          return -1;
        } else if (b.id === documentFilter.id) {
          return 1;
        }
      }
      const a1 = new moment(a.createdDate);
      const b1 = new moment(b.createdDate);

      return b1.valueOf() - a1.valueOf();
    });

  const btn = (
    <FABButtonWithPopper id="create-document-popper">
      <List component="nav">
        <ListItem
          button
          onClick={() => {
            history.push("/customers/" + customerId + "/new-document");
          }}
        >
          <ListItemIcon>
            <PhotoCamera />
          </ListItemIcon>
          Neues Kundendokument erstellen
        </ListItem>
      </List>
    </FABButtonWithPopper>
  );

  if (
    !documents ||
    !displayDocuments ||
    documents.length === 0 ||
    displayDocuments.length === 0
  ) {
    return (
      <>
        <FadeInComponent>
          <div className={classes.nothingFound}>Keine Dokumente gefunden</div>
        </FadeInComponent>
        {btn}
      </>
    );
  }

  return (
    <>
      <FadeInComponent isFilterable={false}>
        <Grid container spacing={1} className={classes.root}>
          {displayDocuments.map(document => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={document.id}>
              <Link
                href={getOpenDocumentHref(customerId, document.id)}
                target="_blank"
                className={classes.link}
              >
                <OverviewListEntry
                  title={document.name}
                  subheader={document.state}
                  icon={<Attachment />}
                  displayAction={false}
                  isNew={!!documentFilter && documentFilter === document.id}
                  className={classes.entry}
                >
                  <div>
                    <span className={classes.label}>Erstellt</span>
                    <span className={classes.value}>
                      {asDisplayDate(document.createdDate)} (
                      {document.authorPartnerId +
                        " / " +
                        document.authorEmployeeId}
                      )
                    </span>
                  </div>
                  <div>
                    <span className={classes.label}>Bearbeitet</span>
                    <span className={classes.value}>
                      {!!document.modifiedDate
                        ? asDisplayDate(document.modifiedDate) +
                          " (" +
                          document.editorPartnerId +
                          " / " +
                          document.editorEmployeeId +
                          ")"
                        : "-"}
                    </span>
                  </div>
                </OverviewListEntry>
              </Link>
            </Grid>
          ))}
        </Grid>
      </FadeInComponent>
      {btn}
    </>
  );
};

export default CustomerDocumentsOverviewList;
