import axios from "../../conf/axios.default";
import moment from "moment";

import * as actionTypes from "./actionTypes";

export function getContractsForCustomer(customerId) {
  let url = "/customers/" + customerId + "/contracts";
  return _getContracts(url);
}

export function getContractsForFamily(familyId) {
  let url = "/families/" + familyId + "/contracts";
  return _getContracts(url);
}

function _getContracts(url) {
  return (dispatch, getState) => {
    const state = getState().contracts;

    dispatch(getContractsAPIIsPending());

    axios
      .get(url)
      .then(res => {
        //TODO
        if (res.error) {
          throw res.error;
        }
        dispatch(getContractsIsSuccess(res.data));
      })
      .catch(error => {
        dispatch(getContractsAPIIsError(error));
      });
  };
}
export function getContract(customerId, contractId) {
  return (dispatch, getState) => {
    /*
    const state = getState().contracts;

    if (
      state.validUntil &&
      state.contracts &&
      !moment(new Date()).isAfter(state.validUntil)
    ) {
      //we have contract and they are valid

      const customer = state.customers.find(c => c.id === parseInt(customerId));
      if (!!customer) {
        dispatch(getContractIsSuccess(customer));
        return;
      }
    }
*/
    //no cache, invalid cache or customer id not in cache
    dispatch(getContractsAPIIsPending());

    let url = "/customers/" + customerId + "/contracts/" + contractId;

    axios
      .get(url)
      .then(res => {
        //TODO
        if (res.error) {
          throw res.error;
        }
        dispatch(getContractIsSuccess(res.data));
      })
      .catch(error => {
        dispatch(getContractsAPIIsError(error));
      });
  };
}

export function createContract(customerId, contract) {
  return (dispatch, getState) => {
    dispatch(createContractAPIIsPending());
    let url = "/customers/" + customerId + "/contracts";

    axios
      .post(url, contract)
      .then(res => {
        //TODO
        if (res.error) {
          throw res.error;
        }
        dispatch(createContractIsSuccess(res.data));
      })
      .catch(error => {
        dispatch(createContractsAPIIsError(error));
      });
  };
}

export function getContractsIsSuccess(contracts, refreshValidity = true) {
  return {
    type: actionTypes.GET_CONTRACTS_IS_SUCCESS,
    contracts: contracts,
    refreshValidity: refreshValidity
  };
}

export function getContractIsSuccess(contract) {
  return {
    type: actionTypes.GET_CONTRACT_IS_SUCCESS,
    contract: contract
  };
}

export function getContractsAPIIsPending() {
  return {
    type: actionTypes.GET_CONTRACTS_API_IS_PENDING
  };
}

export function getContractsAPIIsError(error) {
  return {
    type: actionTypes.GET_CONTRACTS_API_IS_ERROR,
    error: error
  };
}

export function createContractIsSuccess(contract) {
  return {
    type: actionTypes.CREATE_CONTRACT_IS_SUCCESS,
    contract: contract
  };
}
export function createContractAPIIsPending() {
  return {
    type: actionTypes.CREATE_CONTRACT_API_IS_PENDING
  };
}

export function createContractsAPIIsError(error) {
  return {
    type: actionTypes.CREATE_CONTRACT_API_IS_ERROR,
    error: error
  };
}
