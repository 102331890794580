import React, { useEffect, useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Tabs,
  Box,
  Tab,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Attachment } from "@material-ui/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getContract } from "../../store/contracts/actions";
import FABButtonWithPopper from "../../components/UI/FABButtonWithPopper";
import Loading from "../../components/UI/Loading";
import { useHistory } from "react-router-dom";
import ValueWithLabel from "../../components/UI/ValueWithLabel";
import { getContractDocuments } from "../../store/contract-documents/actions";
import ContractDocumentsOverviewList from "./ContractDocumentsOverviewList";
import { getContractFee } from "../../util/Utilities";

const useStyles = makeStyles((theme) => ({
  root: { margin: theme.spacing(2) },
  wrapper: {
    padding: theme.spacing(2),
    paddingLeft: "0",
    borderBottom: "1px solid " + theme.palette.primary.main,
  },
  h2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
    fontSize: 16,
  },
  h4: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    fontStyle: "italic",
  },
  content: {
    fontSize: 12,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

const ContractDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [tabsValue, setTabsValue] = useState(0);

  const params = props.match.params;
  const customerId = params.id;
  const contractId = params.contractId;
  const {
    contract,
    error,
    isPending,
    getContract,
    documents,
    documentsIsError,
    getContractDocuments,
  } = props;

  if (error) {
    history.push("/error", { error: error });
  }
  if (documentsIsError) {
    history.push("/error", { error: documentsIsError });
  }

  useEffect(() => {
    getContract(customerId, contractId);
    getContractDocuments(customerId, contractId);
  }, [customerId, contractId, getContract, getContractDocuments]);

  if (isPending || !contract) return <Loading />;

  const handleChange = (event, value) => {
    setTabsValue(value);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.root}>
          <div className={classes.wrapper}>
            <span className={classes.h2}>{contract.category.name}</span>
            <span className={classes.h4}> ({contract.contractor})</span>
          </div>

          <Tabs
            value={tabsValue}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="primary"
          >
            <Tab label="Details" className={classes.tab} />
            <Tab label="Dokumente" className={classes.tab} />
          </Tabs>
          <TabPanel value={tabsValue} index={0} className={classes.content}>
            <ValueWithLabel
              label="Vertragsnummer"
              value={contract.contractNumber}
            />
            <ValueWithLabel label="Status" value={contract.state} />
            <ValueWithLabel label="Risiko" value={contract.risk} />
            <ValueWithLabel label="Tarif" value={contract.tarrif} />
            <ValueWithLabel
              label="Laufzeit"
              value={
                contract.startDate +
                (contract.endDate ? " - " + contract.endDate : "")
              }
            />
            <ValueWithLabel
              label="Beitrag (lt. ZW)"
              value={getContractFee(contract, true)}
            />
            <ValueWithLabel
              label="Zahlungsweise"
              value={contract.feePaymentType}
            />
            <ValueWithLabel
              label="Zahlungsart"
              value={contract.paymentMethod}
            />
            <ValueWithLabel label="Name der Bank" value={contract.bankName} />
            <ValueWithLabel label="IBAN" value={contract.IBAN} />
            <ValueWithLabel label="BIC" value={contract.BIC} />

            <ValueWithLabel
              label="Verwaltet durch"
              value={contract.controlledBy}
            />

            <ValueWithLabel label="Bemerkung" value={contract.comment || "-"} />
          </TabPanel>
          <TabPanel value={tabsValue} index={1}>
            <ContractDocumentsOverviewList
              documents={documents}
              customerId={customerId}
              contractId={contractId}
            ></ContractDocumentsOverviewList>
          </TabPanel>
        </div>

        <FABButtonWithPopper id="create-contract-popper">
          <List component="nav">
            <ListItem
              button
              onClick={() => {
                history.push(
                  `/customers/${customerId}/contracts/${contractId}/new-contract-document`
                );
              }}
            >
              <ListItemIcon>
                <Attachment />
              </ListItemIcon>
              Neues Vertragsdokument
            </ListItem>
          </List>
        </FABButtonWithPopper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  error: state.contracts.error,
  contract: state.contracts.currentContract,
  isPending: state.contracts.isPending,
  documentsIsPending: state.contractdocuments.isPending,
  documentsIsError: state.contractdocuments.error,
  documents: state.contractdocuments.documents,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getContract: getContract,
      getContractDocuments: getContractDocuments,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContractDetails);
