import {
  ClickAwayListener,
  makeStyles,
  Paper,
  Popper,
  Fab
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useRef, useEffect } from "react";

import { setPopperState } from "../../store/ui/actions";

const useStyles = makeStyles(theme => ({
  grow: {
    flex: 1
  },
  label: {
    width: "200px",
    display: "inline-block"
  },
  fabWrapper: {
    position: "fixed",
    top: "auto",
    right: "0",
    bottom: "64px",
    textAlign: "right"
    //width: "100%"
  },
  fab: {
    margin: theme.spacing(1)
  }
}));

const ButtonWithPopper = props => {
  const classes = useStyles();
  const btnRef = useRef(null);

  useEffect(() => {
    return () => {
      props.setPopperState(props.id, false);
    };
  }, []);

  const isContextMenuOpen = props.popperState[props.id] || false;

  const handleToggleContextMenu = e => {
    props.setPopperState(props.id, !isContextMenuOpen);
  };

  const handleContextMenuClickAway = () => {
    if (isContextMenuOpen) {
      props.setPopperState(props.id, false);
    }
  };

  return (
    <div className={classes.fabWrapper}>
      <Fab
        color="secondary"
        className={classes.fab}
        onClick={handleToggleContextMenu}
        ref={btnRef}
      >
        <Add />
      </Fab>
      <Popper
        open={isContextMenuOpen}
        anchorEl={btnRef.current}
        placement="top-end"
      >
        <ClickAwayListener onClickAway={handleContextMenuClickAway}>
          <Paper>{props.children}</Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

const mapStateToProps = state => ({
  popperState: state.ui.popperState
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPopperState: setPopperState
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ButtonWithPopper);
