import axios from "../conf/axios.auth";
import * as jwt from "jwt-decode";
import { logError } from "./log";

export const isAuthenticated = () => {
  const token = window.sessionStorage.getItem("Token");
  return !!token;
};

export const isFullversion = () => {
  try {
    const token = window.sessionStorage.getItem("Token");
    if (!token) return false;

    const decoded = jwt(token);
    return decoded && decoded.isFullversion;
  } catch (ex) {
    logError(ex);
    return false;
  }
};

export const getToken = () => {
  return window.sessionStorage.getItem("Token");
};

export const storeToken = (token) => {
  //todo, potentially reaplce bearer etc
  window.sessionStorage.setItem("Token", token);
};

export const loginPartner = async (partnerId, partnerPassword) => {
  try {
    const data = {
      partnerId: partnerId,
      partnerPassword: partnerPassword,
    };

    let res = await axios.post("/loginPartner", data);
    return res.data;
  } catch (err) {
    logError(err);
    return [];
  }
};

export const loginEmployee = async (
  partnerId,
  partnerPassword,
  employeeId,
  employeePassword
) => {
  try {
    const data = {
      partnerId: partnerId,
      partnerPassword: partnerPassword,
      employeeId: employeeId,
      employeePassword: employeePassword,
    };

    let res = await axios.post("/login", data);
    return res.data;
  } catch (err) {
    logError(err);
    return false;
  }
};

export const logout = () => {
  window.sessionStorage.removeItem("Token");
};
