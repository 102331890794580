import React from "react";

import { CssBaseline, makeStyles } from "@material-ui/core";
import Header from "./Header";
import BottomNav from "./BottomNav";
import ErrorBoundary from "../UI/ErrorBoundary";

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingBottom: "128px",
    paddingTop: "66px"
  }
}));

export default function Layout(props) {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <Header></Header>

      <div className={classes.wrapper}>
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </div>
      <BottomNav />
    </>
  );
}
