import { makeStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.background.paper
  }
}));

const Error = () => {
  const classes = useStyles();
  const location = useLocation();

  const error = location.state && location.state.error;
  let title = "Bitte entschuldigen Sie";
  let text =
    "es ist ein Fehler aufgetreten. Dies hätte nicht passieren sollen, bitte wenden Sie sich an Ihren KAB Betreuer, sofern der Fehler wiederholt auftritt";
  if (error && error.response && error.response.status) {
    const state = error.response.status;
    switch (state) {
      case 404:
        text =
          "diese Seite wurde nicht gefunden. Falls es sich Ihrer Meinung nach um einen Fehler handelt, wenden Sie sich bitte an Ihren KAB Betreuer";
        break;
      case 401:
        text =
          "es gibt ein Problem mit Ihren Berechtigungen, bitten wenden Sie sich an Ihren KAB Betreuer";
        break;
      case 400:
        text =
          "das System konnte die Anfrage nicht verarbeiten, da wichtige Information fehlen. Bitte wenden Sie sich an Ihren KAB Betreuer";
        break;
      default:
        break;
    }
  }

  return (
    <div className={classes.root}>
      <h1>{title}</h1>
      <span>{text}.</span>
    </div>
  );
};

export default Error;
