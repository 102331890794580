import React from "react";
import {
  Toolbar,
  Switch,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import { FilterList, SwapVert } from "@material-ui/icons";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IconButtonWithPopper from "../../components/UI/IconButtonWithPopper";
import {
  setPopperState,
  setContractsOverviewSettings,
  setContractFilter,
} from "../../store/ui/actions";
import ListFilter from "./ListFilter";
import ListSorter from "./ListSorter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  grow: {
    flex: 1,
  },
}));

const ContractsToolbar = (props) => {
  const {
    contracts,
    settings,
    setSettings,
    contractFilter,
    setContractFilter,
    customer,
  } = props;
  const filterId = "ContractOverviewList-Filter";
  const sorterId = "ContractOverviewList-Sorter";

  const classes = useStyles();

  const contractCategories = [
    ...new Set(contracts.map((c) => c.category.name)),
  ].sort();

  const sortOptions = ["Typ", "Gesellschaft", "Enddatum", "Kosten"];

  const handleFilterClose = (data) => {
    props.setPopperState(filterId, false);

    setSettings(
      settings.sort,
      data,
      settings.doIncludeFamily,
      settings.doShowOnlyActive
    );
  };

  const handleSorterClose = (data) => {
    props.setPopperState(sorterId, false);

    setSettings(
      data,
      settings.filter,
      settings.doIncludeFamily,
      settings.doShowOnlyActive
    );
  };

  const handleIncludeFamilyToggle = (doIncludeFamily) => {
    setSettings(
      settings.sort,
      settings.filter,
      doIncludeFamily,
      settings.doShowOnlyActive
    );
  };

  const handleShowActiveToggle = (doShowOnlyActive) => {
    setSettings(
      settings.sort,
      settings.filter,
      settings.doIncludeFamily,
      doShowOnlyActive
    );
  };

  const familySwitch = (
    <FormControlLabel
      control={
        <Switch
          checked={settings.doIncludeFamily}
          onChange={() => handleIncludeFamilyToggle(!settings.doIncludeFamily)}
          value="1"
          color="secondary"
        />
      }
      label="Verträge aus Familie"
    />
  );

  const toolbarContents = (
    <>
      {!!customer && customer.familyMember && customer.familyMember.length
        ? familySwitch
        : null}
      <FormControlLabel
        control={
          <Switch
            checked={!!settings.doShowOnlyActive}
            onChange={() => handleShowActiveToggle(!settings.doShowOnlyActive)}
            value="1"
            color="secondary"
          />
        }
        label="Nur aktive Verträge"
      />
      <IconButtonWithPopper id={sorterId} icon={<SwapVert />}>
        <ListSorter
          items={sortOptions}
          selected={settings.sort}
          onOk={handleSorterClose}
        />
      </IconButtonWithPopper>
      <IconButtonWithPopper
        id={filterId}
        icon={<FilterList />}
        isActive={settings.filter && settings.filter.length > 0}
      >
        <ListFilter
          items={contractCategories}
          selected={settings.filter}
          onOk={handleFilterClose}
        />
      </IconButtonWithPopper>
    </>
  );

  return (
    <Toolbar>
      <div className={classes.grow} />
      {toolbarContents}
    </Toolbar>
  );
};

const mapStateToProps = (state) => ({
  settings: state.ui.contractsOverviewSettings,
  contractFilter: state.ui.contractsOverviewContractFilter,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPopperState: setPopperState,
      setSettings: setContractsOverviewSettings,
      setContractFilter: setContractFilter,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContractsToolbar);
