import React, { useState } from "react";
import {
  makeStyles,
  ListItem,
  List,
  ListItemIcon,
  FormControl,
  FormLabel
} from "@material-ui/core";
import { Attachment, PhotoCamera } from "@material-ui/icons";
import FileUpload from "../../components/UI/FileUpload";
import ButtonWithPopper from "../../components/UI/ButtonWithPopper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setPopperState } from "../../store/ui/actions";

const useStyles = makeStyles(theme => ({
  input: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: "-1"
  },
  formControl: {
    marginTop: theme.spacing(3)
  },
  btnUpload: {
    marginTop: theme.spacing(1)
  },
  error: {
    color: theme.palette.error.main
  },
  errorText: {
    fontSize: 10
  }
}));

const FileUploadFormControl = props => {
  const bid = "file-upload-cc";
  const classes = useStyles();
  const { isMultiple, label } = props;
  const {
    input,
    meta: { touched, invalid, error, active }
  } = props;
  const [files, setFiles] = useState(input.value || []);

  const handleFileUpload = f => {
    props.setPopperState(bid, false);

    let newFiles = [];
    if (isMultiple) newFiles = [...files, f];
    else newFiles = [f];

    if (input.onChange) input.onChange(newFiles);
    if (props.onChange) props.onChange(newFiles);
    setFiles(newFiles);
  };

  const displayAsError = touched && invalid && !active;

  return (
    <>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel
          component="legend"
          className={displayAsError ? classes.error : null}
        >
          {label}
        </FormLabel>
      </FormControl>
      <ButtonWithPopper
        id={bid}
        label="Datei hochladen"
        icon={<Attachment />}
        variant="outlined"
        className={
          classes.btnUpload + " " + (displayAsError ? classes.error : null)
        }
      >
        <List component="nav">
          <ListItem button>
            <ListItemIcon>
              <PhotoCamera />
            </ListItemIcon>
            <FileUpload
              id="uploadCamera"
              useCamera={true}
              onChange={handleFileUpload}
            ></FileUpload>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <Attachment />
            </ListItemIcon>
            <FileUpload
              onChange={handleFileUpload}
              id="uploadSystem"
            ></FileUpload>
          </ListItem>
        </List>
      </ButtonWithPopper>
      {displayAsError ? (
        <span className={classes.error + " " + classes.errorText}>{error}</span>
      ) : null}
      <List component="nav">
        {files &&
          files.length > 0 &&
          files.map(f => <ListItem key={f.name}>{f.name}</ListItem>)}
      </List>
    </>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPopperState: setPopperState
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadFormControl);
