export const GET_CUSTOMER_DOCUMENTS_API_IS_PENDING =
  "GET_CUSTOMER_DOCUMENTS_API_IS_PENDING";
export const GET_CUSTOMER_DOCUMENTS_API_IS_ERROR =
  "GET_CUSTOMER_DOCUMENTS_API_IS_ERROR";

export const GET_CUSTOMER_DOCUMENTS = "GET_CUSTOMER_DOCUMENTS";
export const GET_CUSTOMER_DOCUMENT = "GET_CUSTOMER_DOCUMENT";
export const GET_CUSTOMER_DOCUMENTS_IS_SUCCESS =
  "GET_CUSTOMER_DOCUMENTS_IS_SUCCESS";
export const GET_CUSTOMER_DOCUMENT_IS_SUCCESS =
  "GET_CUSTOMER_DOCUMENT_IS_SUCCESS";
