import axios from "../conf/axios.default";
import { logError } from "./log";

export const createDocument = async (customerId, data) => {
  let url = "/customers/" + customerId + "/documents";

  try {
    let res = await axios.post(url, data);
    return res.data;
  } catch (err) {
    logError(err);
    return null;
  }
};
