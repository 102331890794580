import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, Divider } from "@material-ui/core";

import AlphabetNavigation from "../../components/UI/AlphabetNaviagion";
import Loading from "../../components/UI/Loading";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex"
  },
  list: {
    flexGrow: 1
  },
  nothingFound: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(3)
  },

  root: {
    width: "calc(100vw - 95px)"
  }
}));

export default ({
  customers,
  isPending,
  disableNavigation,
  handleSelectCustomerClick,
  switchActiveChar,
  activeChar
}) => {
  const classes = useStyles();

  const nothing = (
    <div className={classes.nothingFound}>Keine Personen gefunden...</div>
  );
  const nav = !disableNavigation ? (
    <AlphabetNavigation
      className={classes.nav}
      activeChar={activeChar}
      switchActiveChar={switchActiveChar}
    ></AlphabetNavigation>
  ) : null;

  const list = (
    <div className={classes.root}>
      <List component="nav" className={classes.list}>
        {customers &&
          customers.map(customer => (
            <div key={customer.id}>
              <ListItem
                button
                onClick={() => handleSelectCustomerClick(customer)}
              >
                <ListItemText
                  primary={
                    customer.companyName ||
                    `${customer.lastName}, ${customer.firstName}`
                  }
                  secondary={`${customer.street}, ${customer.zip} ${customer.city}`}
                />
              </ListItem>
              <Divider />
            </div>
          ))}
      </List>
    </div>
  );

  return (
    <>
      <div className={classes.container}>
        {isPending ? (
          <Loading />
        ) : customers && customers.length > 0 ? (
          list
        ) : (
          nothing
        )}
      </div>

      {nav}
    </>
  );
};
