import moment from "moment";

import * as actionTypes from "./actionTypes";
import * as Utilities from "../../util/Utilities";

const initialState = {
  me: null,
  employees: {}
};

const getUpdatedCustomersIsSuccess = (state, action) => {
  const updated = Utilities.updateStateObject(state, {
    isPending: false,
    customers: action.customers
  });

  if (action.refreshValidity) {
    const d = process.env.REACT_APP_API_CACHE_DURATION;
    updated.validUntil = moment(new Date()).add(JSON.parse(d));
  }
  return updated;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EMPLOYEES_API_IS_PENDING:
      return Utilities.updateStateObject(state, {
        isPending: true,
        error: null
      });

    case actionTypes.GET_ME_IS_SUCCESS:
      return Utilities.updateStateObject(state, {
        isPending: false,
        me: action.me
      });

    case actionTypes.GET_EMPLOYEE_IS_SUCCESS:
      const newEmployees = { ...state.employees };
      newEmployees[action.employee.id] = action.employee;

      return Utilities.updateStateObject(state, {
        isPending: false,
        employees: newEmployees
      });

    case actionTypes.GET_EMPLOYEES_API_IS_ERROR:
      return Utilities.updateStateObject(state, {
        isPending: false,
        error: action.error
      });
    default:
      return state;
  }
};
