import React from "react";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import CustomerOverview from "./container/CustomerOverview/CustomerOverview";
import Customer from "./container/Customer/Customer";
import Login from "./container/Login/Login";
import PrivateRoute from "./components/PrivateRoute";
import "./App.css";
import SearchCenter from "./container/SearchCenter/SearchCenter";
import MyProfile from "./container/MyProfile/MyProfile";
import Error from "./container/Error/Error";

function App() {
  let routes = (
    <Router basename={"#"}>
      <Switch>
        <PrivateRoute path="/" exact component={CustomerOverview} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/customers/:id" component={Customer} />
        <PrivateRoute path="/customers" component={CustomerOverview} />
        <PrivateRoute path="/my-profile" component={MyProfile} />
        <PrivateRoute path="/search-center" component={SearchCenter} />
        <PrivateRoute path="/error" component={Error} />
        <Route component={null} />
      </Switch>
    </Router>
  );

  return <>{routes}</>;
}

export default App;
