import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";

import Loading from "../../components/UI/Loading";
import ContractOverviewList from "./ContractOverviewList";
import ContractsToolbar from "./ContractsToolbar";
import {
  getContractsForFamily,
  getContractsForCustomer
} from "../../store/contracts/actions";

import { setContractFilter } from "../../store/ui/actions";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  }
}));

const ContractOverview = props => {
  const classes = useStyles();
  const history = useHistory();

  const {
    contracts,
    error,
    isCustomerPending,
    isContractsPending,
    getContractsForCustomer,
    getContractsForFamily,
    customer,
    overviewSettings,

    setContractFilter
  } = props;

  if (error) {
    history.push("/error", { error: error });
  }

  useEffect(() => {
    if (isCustomerPending || !customer) return;

    if (overviewSettings.doIncludeFamily && customer.familyId) {
      getContractsForFamily(customer.familyId);
    } else {
      getContractsForCustomer(customer.id);
    }

    return () => {
      setContractFilter(null);
    };
  }, [
    getContractsForFamily,
    getContractsForCustomer,
    customer,
    overviewSettings.doIncludeFamily,
    isCustomerPending,
    setContractFilter
  ]);

  if (isCustomerPending || isContractsPending) return <Loading />;

  return (
    <>
      <ContractsToolbar contracts={contracts} customer={customer} />
      <Hidden smUp>
        <ContractOverviewList
          contracts={contracts}
          customer={customer}
          settings={props.overviewSettings}
          contractFilter={props.contractFilter}
        />
      </Hidden>
      <Hidden xsDown>
        <ContractOverviewList
          contracts={contracts}
          customer={customer}
          settings={props.overviewSettings}
          contractFilter={props.contractFilter}
        />
      </Hidden>
    </>
  );
};

const mapStateToProps = state => ({
  error: state.contracts.error,
  contracts: state.contracts.contracts,
  isContractsPending: state.contracts.isPending,
  overviewSettings: state.ui.contractsOverviewSettings,
  contractFilter: state.ui.contractsOverviewContractFilter
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getContractsForCustomer: customerId =>
        getContractsForCustomer(customerId),
      getContractsForFamily: familyId => getContractsForFamily(familyId),
      setContractFilter: setContractFilter
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContractOverview);
