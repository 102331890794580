import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  makeStyles,
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Toolbar
} from "@material-ui/core";
import { Home, EuroSymbol, Healing, FindInPage } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  appBar: {
    top: "auto",
    bottom: "0"
  },
  navRoot: {
    backgroundColor: theme.palette.primary.main
  },
  actionRoot: {
    color: "white !important"
  }
}));

const BottomNav = props => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const { currentCustomer } = props;

  const [value, setValue] = React.useState("customers");

  const handleChange = (e, v) => {
    setValue(v);
    if (v === "customers" && !!currentCustomer) {
      const name = currentCustomer.companyName || currentCustomer.lastName;
      history.push("/customers", {
        activeChar: name.charAt(0)
      });
    } else {
      history.push("/" + v);
    }
  };
  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <BottomNavigation
        value={value}
        onChange={handleChange}
        className={classes.navRoot}
      >
        <BottomNavigationAction
          className={classes.actionRoot}
          label="Kundenübersicht"
          value="customers"
          icon={<Home />}
        />
      </BottomNavigation>
    </AppBar>
  );
};
const mapStateToProps = state => ({
  currentCustomer: state.ui.currentCustomer
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BottomNav);
