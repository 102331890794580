import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  input: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: "-1"
  }
}));

const FileUpload = props => {
  const classes = useStyles();
  const { id, useCamera } = props;

  const readFileAsync = file => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onloadend = function() {
        if (reader.error) console.log(reader.error.message);
      };

      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const processFiles = async e => {
    const files = e.currentTarget.files;
    for (var i = 0; i < files.length; i++) {
      var file = files[i];
      var f = {
        name: file.name,
        size: file.size,
        type: file.type
      };
      var data = await readFileAsync(file);
      f.data = data;
      props.onChange(f);
    }
  };
  let label = "von Dateien";
  let inputProps = {
    accept: ".pdf,image/*"
  };

  if (useCamera) {
    inputProps.accept = "image/*";
    inputProps.capture = "environment";
    label = "von Kamera";
  }

  return (
    <>
      <input
        onChange={processFiles}
        className={classes.input}
        type="file"
        name={id}
        id={id}
        {...inputProps}
      />
      <label htmlFor={id}>{label}</label>
    </>
  );
};

export default FileUpload;
