import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  wrapper: { marginBottom: theme.spacing(1) },
  label: {
    //width: "10em",
    display: "block",
    fontWeight: "bold"
  },
  value: {
    display: "block"
  }
}));

export default props => {
  const classes = useStyles();
  const { label, value } = props;

  if (!value) return null;

  return (
    <div className={classes.wrapper}>
      <span className={classes.label}>{label}</span>
      <span className={classes.value}>{value}</span>
    </div>
  );
};
