import axios from "../../conf/axios.default";
import moment from "moment";

import * as actionTypes from "./actionTypes";

export function getCustomers(activeChar, sortBy, sortOrder) {
  return (dispatch, getState) => {
    const state = getState().customers;

    if (
      state.validUntil &&
      state.customers &&
      !moment(new Date()).isAfter(state.validUntil) &&
      false //TODO
    ) {
      //use value from cache
      dispatch(getCustomersIsSuccess(state.customers, false));
    } else {
      dispatch(getCustomersAPIIsPending());

      axios
        .get("/customers?char=" + activeChar)
        .then(res => {
          //TODO
          if (res.error) {
            throw res.error;
          }

          dispatch(
            getCustomersIsSuccess(activeChar, sortBy, sortOrder, res.data)
          );
        })
        .catch(error => {
          dispatch(getCustomersAPIIsError(error));
        });
    }
  };
}

export function ftSearchCustomers(searchQuery, sortBy, sortOrder) {
  return (dispatch, getState) => {
    dispatch(getCustomersAPIIsPending());

    axios
      .get("/customers?query=" + searchQuery)
      .then(res => {
        //TODO
        if (res.error) {
          throw res.error;
        }

        dispatch(
          getCustomersFTSearchIsSuccess(
            searchQuery,
            sortBy,
            sortOrder,
            res.data
          )
        );
      })
      .catch(error => {
        dispatch(getCustomersAPIIsError(error));
      });
  };
}

export function getCustomer(customerId, includeFamilyMember = true) {
  return (dispatch, getState) => {
    /*
    const state = getState().customers;
   
    if (
      state.validUntil &&
      state.customers &&
      !moment(new Date()).isAfter(state.validUntil) &&
    ) {
      //we have customer and they are valid
      const customer = state.customers.find(c => c.id === parseInt(customerId));
      if (!!customer) {
        dispatch(getCustomerIsSuccess(customer));
        return;
      }
    }
    if (!!state.currentCustomer && state.currentCustomer.id === customerId) {
      //we have customer opend and they are valid
      dispatch(getCustomerIsSuccess(state.currentCustomer));
      return;
    }
*/
    //no cache, invalid cache or customer id not in cache
    dispatch(getCustomersAPIIsPending());

    axios
      .get("/customers/" + customerId)
      .then(r1 => {
        //TODO
        if (r1.error) {
          throw r1.error;
        }

        if (includeFamilyMember && r1.data.familyId > 0) {
          axios
            .get("/families/" + r1.data.familyId)
            .then(r2 => {
              const c = r1.data;
              c.familyMember = r2.data.filter(fm => fm.id !== c.id);
              dispatch(getCustomerIsSuccess(c));
            })
            .catch(error => {
              dispatch(getCustomersAPIIsError(error));
            });
        } else {
          dispatch(getCustomerIsSuccess(r1.data));
        }
      })
      .catch(error => {
        dispatch(getCustomersAPIIsError(error));
      });
  };
}

export function getCustomersIsSuccess(
  activeChar,
  sortBy,
  sortOrder,
  customers,
  refreshValidity = true
) {
  return {
    type: actionTypes.GET_CUSTOMERS_IS_SUCCESS,
    activeChar: activeChar,
    sortBy: sortBy,
    sortOrder: sortOrder,
    customers: customers,
    refreshValidity: refreshValidity
  };
}

export function getCustomersFTSearchIsSuccess(
  searchQuery,
  sortBy,
  sortOrder,
  customers,
  refreshValidity = true
) {
  return {
    type: actionTypes.GET_CUSTOMERS_IS_SUCCESS,
    searchQuery: searchQuery,
    sortBy: sortBy,
    sortOrder: sortOrder,
    customers: customers,
    refreshValidity: refreshValidity
  };
}

export function getCustomerIsSuccess(customer) {
  return {
    type: actionTypes.GET_CUSTOMER_IS_SUCCESS,
    customer: customer
  };
}

export function getCustomersAPIIsPending() {
  return {
    type: actionTypes.GET_CUSTOMERS_API_IS_PENDING
  };
}

export function getCustomersAPIIsError(error) {
  return {
    type: actionTypes.GET_CUSTOMERS_API_IS_ERROR,
    error: error
  };
}
