import React, { useEffect } from "react";
import axios from "../conf/axios.default";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const RFWTextField = ({
  label,
  placeholder,
  input,
  meta: { touched, invalid, error, active },
  ...custom
}) => {
  return (
    <TextField
      label={label}
      onKeyPress={event => {
        if (event.key === "Enter") {
          event.target.blur();
        }
      }}
      fullWidth
      margin="normal"
      placeholder={placeholder}
      error={touched && invalid && !active}
      helperText={touched && !active && error}
      {...input}
      {...custom}
    />
  );
};

export const RFWSwitch = ({
  label,
  input,
  meta: { touched, invalid, error, active },
  ...custom
}) => {
  return (
    <FormControlLabel
      {...custom}
      control={
        <Switch
          checked={input.value ? true : false}
          onChange={input.onChange}
        />
      }
      label={label}
    />
  );
};

export const RFWAutoComplete = ({
  label,
  _options,
  optionLabel,
  optionValue,
  placeholder,
  url,
  input,
  freeSolo,
  meta: { touched, invalid, error, active },
  ...custom
}) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);

  const [options, setOptions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    (async () => {
      let res = await axios.get(url);
      const data = res.data;
      setOptions(data);
      setIsLoading(false);
    })();

    return () => {};
  }, [url]);

  return (
    <>
      <Autocomplete
        freeSolo={!!freeSolo}
        className={custom.className}
        options={options.map(option =>
          !optionValue ? option : option[optionValue]
        )}
        clearText="Löschen"
        closeText="Schließen"
        noOptionsText="nicht gefunden"
        renderInput={params => (
          <TextField
            {...params}
            onKeyPress={event => {
              if (event.key === "Enter") {
                event.target.blur();
              }
            }}
            label={label}
            placeholder={placeholder}
            error={touched && invalid && !active}
            helperText={touched && !active && error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            {...input}
            {...custom}
          />
        )}
      />
    </>
  );
};

export const RFWCheckbox = ({ input, label }) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
        />
      }
      label={label}
    />
  </div>
);

export const RFWRadioButton = ({ input, ...rest }) => (
  <FormControl>
    <RadioGroup {...input} {...rest}>
      <FormControlLabel value="female" control={<Radio />} label="Female" />
      <FormControlLabel value="male" control={<Radio />} label="Male" />
      <FormControlLabel value="other" control={<Radio />} label="Other" />
    </RadioGroup>
  </FormControl>
);

export const RFWFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

export const RFWSelectField = ({
  id,
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl error={touched && error}>
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <Select
      native
      {...input}
      {...custom}
      inputProps={{
        name: id,
        id: id
      }}
    >
      {children}
    </Select>
    {RFWFromHelper({ touched, error })}
  </FormControl>
);
