import React from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import { Phone, ChatOutlined, WhatsApp } from "@material-ui/icons";

import { parsePhoneNumberFromString } from "libphonenumber-js";

import { isMobileDevice } from "../../util/Utilities";

const useStyles = makeStyles(theme => ({
  entry: { display: "flex" },
  number: { flex: 1, display: "inline-flex", alignItems: "center" },
  button: { marginLeft: theme.spacing(1) }
}));

const PhoneLink = props => {
  const classes = useStyles();
  if (!props.number) return null;

  let number = null;
  let snumber = "";
  let isWhatsAppCompatible = true;

  number = parsePhoneNumberFromString(props.number);
  number = number || parsePhoneNumberFromString(props.number, "DE");
  if (!number) {
    snumber = props.number;
    isWhatsAppCompatible = false;
  } else {
    snumber = number.format("E.164");
  }

  const executeNumber = type => {
    let url = "";
    switch (type) {
      case "call":
        url = "tel:" + snumber;
        break;
      case "whatsapp":
        url = "https://wa.me/" + snumber;
        break;
      case "message":
        url = "sms:" + snumber;
        break;
      default:
    }

    window.open(url);
  };

  /**
  if (!isMobileDevice()) {
    return <span className={classes.number}>{props.number}</span>;
  }
  */

  const whatsApp = (
    <IconButton
      className={classes.button}
      color="inherit"
      size="small"
      onClick={() => executeNumber("whatsapp")}
    >
      <WhatsApp fontSize="small" />
    </IconButton>
  );

  return (
    <div className={classes.entry}>
      <span className={classes.number}>
        {props.label ? props.label + " " : ""}
        {props.number}
      </span>

      <span>
        <IconButton
          className={classes.button}
          color="inherit"
          size="small"
          onClick={() => executeNumber("call")}
        >
          <Phone fontSize="small" />
        </IconButton>
        {props.displayWhatsApp && isWhatsAppCompatible ? whatsApp : null}
        <IconButton
          className={classes.button}
          color="inherit"
          size="small"
          onClick={() => executeNumber("message")}
        >
          <ChatOutlined fontSize="small" />
        </IconButton>
      </span>
    </div>
  );
};

export default PhoneLink;
