export const SET_CONTRACTS_OVERVIEW_SETTINGS =
  "SET_CONTRACTS_OVERVIEW_SETTINGS";

export const SET_CONTRACTS_OVERVIEW_CONTRACT_FILTER =
  "SET_CONTRACTS_OVERVIEW_CONTRACT_FILTER";
export const SET_DOCUMENTS_OVERVIEW_DOCUMENT_FILTER =
  "SET_DOCUMENTS_OVERVIEW_DOCUMENT_FILTER";

export const SET_POPPER_STATE = "SET_POPPER_STATE";

export const SET_IS_SEARCH_DISPLAYED = "SET_IS_SEARCH_DISPLAYED";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const SET_CURRENT_CUSTOMER = "SET_CURRENT_CUSTOMER";
export const SET_CUSTOMER_OVERVIEW_CURRENT_SETTINGS =
  "SET_CUSTOMER_OVERVIEW_CURRENT_SETTINGS";
