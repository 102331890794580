import axios from "../../conf/axios.default";
import moment from "moment";

import * as actionTypes from "./actionTypes";

export function getCustomerDocuments(customerId) {
  return (dispatch, getState) => {
    const state = getState().customerdocuments;

    if (
      state.validUntil &&
      state.contracts &&
      !moment(new Date()).isAfter(state.validUntil) &&
      false
    ) {
      //use value from cache
      dispatch(getCustomerDocumentsIsSuccess(state.contracts, false));
    } else {
      dispatch(getCustomerDocumentsAPIIsPending());

      let url = "/customers/" + customerId + "/documents";

      axios
        .get(url)
        .then(res => {
          //TODO
          if (res.error) {
            throw res.error;
          }
          dispatch(getCustomerDocumentsIsSuccess(res.data));
        })
        .catch(error => {
          dispatch(getCustomerDocumentsAPIIsError(error));
        });
    }
  };
}

/*
export function getCustomerDocument(customerId, documentId) {
  return (dispatch, getState) => {
    //no cache, invalid cache or customer id not in cache
    dispatch(getCustomerDocumentsAPIIsPending());
    let url = "/customers/" + customerId + "/documents/" + documentId;

    axios
      .get(url)
      .then(res => {
        //TODO
        if (res.error) {
          throw res.error;
        }
        dispatch(getCustomerDocumentIsSuccess(res.data));
      })
      .catch(error => {
        dispatch(getCustomerDocumentsAPIIsError(error));
      });
  };
}
*/
export function getCustomerDocumentsIsSuccess(
  documents,
  refreshValidity = true
) {
  return {
    type: actionTypes.GET_CUSTOMER_DOCUMENTS_IS_SUCCESS,
    documents: documents,
    refreshValidity: refreshValidity
  };
}

export function getCustomerDocumentIsSuccess(document) {
  return {
    type: actionTypes.GET_CUSTOMER_DOCUMENT_IS_SUCCESS,
    document: document
  };
}

export function getCustomerDocumentsAPIIsPending() {
  return {
    type: actionTypes.GET_CUSTOMER_DOCUMENTS_API_IS_PENDING
  };
}

export function getCustomerDocumentsAPIIsError(error) {
  return {
    type: actionTypes.GET_CUSTOMER_DOCUMENTS_API_IS_ERROR,
    error: error
  };
}
