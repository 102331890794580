import {
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  makeStyles
} from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useRef, useEffect } from "react";

import { setPopperState } from "../../store/ui/actions";

const useStyles = makeStyles(theme => ({
  container: {
    border: "1px solid " + theme.palette.primary.main
  },
  containerPopper: { zIndex: 9999 },
  btnActive: {
    padding: theme.spacing(1),
    //backgroundColor: theme.palette.secondary.main,
    border: "2px solid " + theme.palette.secondary.main,
    //color: theme.palette.secondary.contrastText
    color: theme.palette.secondary.main
  }
}));

const IconButtonWithPopper = props => {
  const classes = useStyles();
  const btnRef = useRef(null);

  const { id, color, icon, isActive } = props;

  const isContextMenuOpen = props.popperState[id] || false;

  useEffect(() => {
    return () => {
      props.setPopperState(id, false);
    };
  }, []);

  const handleToggleContextMenu = e => {
    props.setPopperState(id, !isContextMenuOpen);
  };

  const handleContextMenuClickAway = () => {
    if (isContextMenuOpen) {
      props.setPopperState(id, false);
    }
  };

  return (
    <div>
      <IconButton
        color={color || "inherit"}
        onClick={handleToggleContextMenu}
        ref={btnRef}
        classes={{
          root: isActive ? classes.btnActive : ""
        }}
      >
        {icon}
      </IconButton>
      <Popper
        className={classes.containerPopper}
        open={isContextMenuOpen}
        anchorEl={btnRef.current}
        placement="bottom-end"
      >
        <ClickAwayListener onClickAway={handleContextMenuClickAway}>
          <Paper className={classes.container}>{props.children}</Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

const mapStateToProps = state => ({
  popperState: state.ui.popperState
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPopperState: setPopperState
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IconButtonWithPopper);
