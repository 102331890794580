import React, { useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";

import CustomerOverviewList from "./CustomerOverviewList";

import { getCustomers, ftSearchCustomers } from "../../store/customers/actions";
import { setCustomerOverviewCurrentSettings } from "../../store/ui/actions";

import useSearch from "../../hooks/useSearch";

const CustomerOverview = props => {
  const history = useHistory();
  const [searchValue] = useSearch();

  const {
    customers,
    error,
    isPending,
    getCustomers,
    ftSearchCustomers,
    customerOverviewCurrentSettings,
    setCustomerOverviewCurrentSettings
  } = props;

  if (error) {
    history.push("/error", { error: error });
  }

  const handleSelectCustomerClick = customer => {
    history.push(`customers/${customer.id}`, {
      customer: customer
    });
  };

  const switchActiveChar = char => {
    setCustomerOverviewCurrentSettings({
      activeChar: char
    });
  };

  useEffect(() => {
    if (searchValue) {
      ftSearchCustomers(searchValue);
      if (searchValue !== customerOverviewCurrentSettings.searchValue) {
        setCustomerOverviewCurrentSettings({
          searchValue: searchValue,
          activeChar: customerOverviewCurrentSettings.activeChar
        });
      }
    } else {
      getCustomers(customerOverviewCurrentSettings.activeChar);
    }
  }, [
    getCustomers,
    customerOverviewCurrentSettings.activeChar,
    searchValue,
    ftSearchCustomers,
    setCustomerOverviewCurrentSettings,
    customerOverviewCurrentSettings.searchValue
  ]);

  return (
    <CustomerOverviewList
      customers={customers}
      isPending={isPending}
      disableNavigation={!!searchValue}
      handleSelectCustomerClick={handleSelectCustomerClick}
      switchActiveChar={switchActiveChar}
      activeChar={customerOverviewCurrentSettings.activeChar}
    ></CustomerOverviewList>
  );
};

const mapStateToProps = state => ({
  error: state.customers.error,
  customers: state.customers.customers,
  isPending: state.customers.isPending,
  customerOverviewCurrentSettings: state.ui.customerOverviewCurrentSettings
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCustomers: getCustomers,
      ftSearchCustomers: ftSearchCustomers,
      setCustomerOverviewCurrentSettings: setCustomerOverviewCurrentSettings
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOverview);
