import axios from "../../conf/axios.default";

import * as actionTypes from "./actionTypes";

export function getMe() {
  return (dispatch, getState) => {
    const state = getState().employees;

    const me = state.me;

    if (me) {
      //use value from cache
      dispatch(getMeIsSuccess(me));
    } else {
      dispatch(getEmployeesAPIIsPending());

      axios
        .get("/employees/me")
        .then(res => {
          //TODO
          if (res.error) {
            throw res.error;
          }

          dispatch(getMeIsSuccess(res.data));
        })
        .catch(error => {
          dispatch(getEmployeesAPIIsError(error));
        });
    }
  };
}

export function getEmployee(employeeId) {
  return (dispatch, getState) => {
    const state = getState().employees;
    const employee = state.employees[employeeId];

    if (employee) {
      //use value from cache
      dispatch(getEmployeeIsSuccess(employee));
    } else {
      dispatch(getEmployeesAPIIsPending());

      axios
        .get("/employees/" + employeeId)
        .then(res => {
          //TODO
          if (res.error) {
            throw res.error;
          }

          dispatch(getEmployeeIsSuccess(res.data));
        })
        .catch(error => {
          dispatch(getEmployeesAPIIsError(error));
        });
    }
  };
}

export function getEmployeeIsSuccess(employee) {
  return {
    type: actionTypes.GET_EMPLOYEE_IS_SUCCESS,
    employee: employee
  };
}

export function getMeIsSuccess(employee) {
  return {
    type: actionTypes.GET_ME_IS_SUCCESS,
    me: employee
  };
}

export function getEmployeesAPIIsPending() {
  return {
    type: actionTypes.GET_EMPLOYEES_API_IS_PENDING
  };
}

export function getEmployeesAPIIsError(error) {
  return {
    type: actionTypes.GET_EMPLOYEES_API_IS_ERROR,
    error: error
  };
}
