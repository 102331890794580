export const GET_CONTRACTS_API_IS_PENDING = "GET_CONTRACTS_API_IS_PENDING";
export const GET_CONTRACTS_API_IS_ERROR = "GET_CONTRACTS_API_IS_ERROR";

export const GET_CONTRACTS = "GET_CONTRACTS";
export const GET_CONTRACT = "GET_CONTRACT";
export const GET_CONTRACTS_IS_SUCCESS = "GET_CONTRACTS_IS_SUCCESS";
export const GET_CONTRACT_IS_SUCCESS = "GET_CONTRACT_IS_SUCCESS";

export const CREATE_CONTRACT_API_IS_PENDING = "CREATE_CONTRACT_API_IS_PENDING";
export const CREATE_CONTRACT_API_IS_ERROR = "CREATE_CONTRACT_API_IS_ERROR";
export const CREATE_CONTRACT_IS_SUCCESS = "CREATE_CONTRACT_IS_SUCCESS";
