import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "0.875rem"
      }
    }
  },
  palette: {
    primary: {
      main: "#000"
    },
    secondary: {
      main: "#ab8943"
    },
    hurz: {
      main: "red"
    }
  },
  typography: {
    useNextVariants: true
  }
});

export default theme;
