import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  center: {
    textAlign: "center"
  },
  progress: {
    margin: theme.spacing(2)
  }
}));

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.center}>
      <CircularProgress className={classes.progress} />
    </div>
  );
};
